import React from "react";
import { NavLink } from "react-router-dom";

interface INavigationLinkProps {
  to: string;
  Icon: any;
  label: string;
  isSideMenuOpen: boolean;
  toggle: () => void;
}

const NavigationLink = ({
  to,
  Icon,
  label,
  isSideMenuOpen,
  toggle,
}: INavigationLinkProps) => (
  <NavLink
    to={to}
    onClick={toggle}
    className={`text-center flex ${isSideMenuOpen ? "w-full px-5 gap-x-2 justify-start" : "w-[49px] justify-center"} h-11 rounded-md items-center`}
  >
    <Icon className="w-6 h-6" />
    {isSideMenuOpen && <p>{label}</p>}
  </NavLink>
);

export default NavigationLink;
