import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import {
  Alert,
  Button,
  Divider,
  LoadingOverlay,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon, IconOptions } from "components/Icon/Icon";
import npsLogo from "assets/images/npsFullLogo.svg";
import signInSideImg from "assets/images/SignInImageSide.png";
import { IGoogleSignInRequest, ISignInRequest } from "data/types/request";
import { LocalStorageKeys } from "data/constants/storage.constants";
import { useAuthContext } from "context/Auth/AuthContext";
import { AuthActionsEnum } from "context/Auth/AuthContextValues";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  getSignInWithGoogleLink,
  LOGIN_REDIRECT_URI,
} from "data/constants/api.constants";
import { ISignInResponse } from "data/types/response";
import useGetUserDetails from "hooks/useGetUserDetails";

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [searchParams] = useSearchParams();

  const { auth, dispatch } = useAuthContext();

  const authCode = searchParams.get("code") || "";
  const errorDescription = searchParams.get("error_description") || "";
  const { getUserDetails } = useGetUserDetails();

  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    setLoadingPage(true);
    const request: IGoogleSignInRequest = {
      code: authCode,
      redirecturi: LOGIN_REDIRECT_URI,
    };

    try {
      const signInData = (await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.SIGN_IN_WITH_GOOGLE,
        request,
      )) as ISignInResponse;
      if (signInData) {
        dispatch({
          type: AuthActionsEnum.SET_IS_LOGGED_IN,
          payload: {
            isLoggedIn: true,
          },
        });
        localStorage.setItem(
          LocalStorageKeys.ID_PROVIDER,
          signInData?.IdProvider,
        );
        localStorage.setItem(
          LocalStorageKeys.ACCESS_TOKEN,
          String(signInData.AccessToken),
        );
        localStorage.setItem(
          LocalStorageKeys.REFRESH_TOKEN,
          String(signInData.RefreshToken),
        );
        await getUserDetails();
      }
    } catch (err: any) {
      console.log("SignIn google API:", err);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    if (
      errorDescription &&
      errorDescription.includes("same email was created")
    ) {
      notifications.show({
        color: "red",
        title: "SignUp with google",
        message: "Account with the same email was created",
        autoClose: 5000,
      });
    }
    if (authCode && !auth.isLoggedIn) {
      signInWithGoogle();
    }
    // eslint-disable-next-line
  }, []);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length < 8 ? "Password must have at least 8 letters" : null,
    },
  });

  const submitSignIn = async (value: (typeof form)["values"]) => {
    setLoading(true);
    const request: ISignInRequest = {
      email: value.email,
      password: value.password,
    };
    try {
      const signInData = (await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.SIGN_IN,
        request,
      )) as ISignInResponse;
      if (signInData) {
        dispatch({
          type: AuthActionsEnum.SET_IS_LOGGED_IN,
          payload: {
            isLoggedIn: true,
          },
        });
        localStorage.setItem(
          LocalStorageKeys.ID_PROVIDER,
          signInData?.IdProvider,
        );
        localStorage.setItem(
          LocalStorageKeys.ACCESS_TOKEN,
          String(signInData.AccessToken),
        );
        localStorage.setItem(
          LocalStorageKeys.REFRESH_TOKEN,
          String(signInData.RefreshToken),
        );
        await getUserDetails();
        navigate(`/tools`);
      }
    } catch (err: any) {
      setError(err.Error);
      console.log("SignIn API:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-full">
      <LoadingOverlay
        visible={loadingPage}
        classNames={{ loader: "text-2xl font-bold" }}
        loaderProps={{
          children: "Signing in, please wait...",
        }}
      />
      <div className="flex max-sm:hidden h-full w-1/2">
        <img
          src={signInSideImg}
          alt="sideImg"
          className="h-full w-full lg:object-cover"
        />
      </div>
      <div className="max-sm:w-full content-center overflow-y-scroll sm:w-1/2">
        <div className="flex w-full flex-col items-center justify-center p-12">
          <div className="flex w-full flex-col items-center max-w-[500px]">
            <div className="flex items-end gap-1 max-sm:hidden">
              <img src={npsLogo} alt="logo" width={200} />
              <div className="text-[7.55px] uppercase pb-1">beta</div>
            </div>
            <div className="my-6 text-center font-semibold text-[32px]">
              Sign in with NPS
            </div>
            <form
              className="flex w-full flex-col gap-5"
              onSubmit={form.onSubmit(submitSignIn)}
            >
              <TextInput
                radius="md"
                label="Email"
                placeholder="Enter your email"
                key={form.key("email")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[54px] border border-gray-200 shadow",
                }}
                {...form.getInputProps("email")}
              />
              <div>
                <PasswordInput
                  radius="md"
                  label="Password"
                  placeholder="Enter your password"
                  key={form.key("password")}
                  classNames={{
                    label: "mb-3 text-sm font-medium",
                    input:
                      "bg-whisper-gray text-gray-500 h-[54px] border border-gray-200 shadow",
                  }}
                  {...form.getInputProps("password")}
                />
                <div
                  className="mt-3 cursor-pointer text-end text-sm text-gray-400"
                  onClick={() => navigate("/forgotpassword")}
                >
                  Forgot Password?
                </div>
              </div>
              <Button
                disabled={loading}
                loading={loading}
                type="submit"
                size="lg"
                radius="lg"
                className="w-full h-[50px]"
              >
                Sign In
              </Button>
            </form>
            {error && (
              <Alert
                title="Something went wrong!"
                radius="md"
                color="red"
                className="mt-5 w-full"
              >
                {error}
              </Alert>
            )}
            <Divider
              my="xl"
              label="or"
              labelPosition="center"
              className="w-full"
              classNames={{ label: "text-lg" }}
            />
            <div className="flex w-full flex-col items-center gap-6">
              <Button
                variant="outline"
                color="gray"
                size="lg"
                radius="xl"
                className="w-full border-gray-300 text-black h-[50px]"
                onClick={() =>
                  window.open(
                    getSignInWithGoogleLink(LOGIN_REDIRECT_URI),
                    "_self",
                  )
                }
              >
                <Icon option={IconOptions.GOOGLE} size={30} className="mr-2" />
                Sign In with Google
              </Button>
              <div className="flex flex-col gap-5 text-center text-sm font-medium text-gray-400 max-w-[300px]">
                <div>
                  By continuing, you agree to our Terms of Service and Privacy
                  Policy.
                </div>
                <div>
                  Don't have an account?{" "}
                  <span
                    className="cursor-pointer text-blue-500"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
