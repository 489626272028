import { useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Button,
  PinInput,
  Alert,
} from "@mantine/core";
import npsLogo from "assets/images/npsFullLogo.svg";
import forgotPasswordSideImg from "assets/images/forgotPasswordSideImg.png";
import {
  IGetForgotPasswordCodeRequest,
  ISetPasswordRequest,
} from "data/types/request";
import { commonRequest } from "services/api/commonRequest";
import { ApiEndpoint, ApiMethod } from "data/constants/api.constants";

enum ForgotPasswordPages {
  CONFIRM_PASSWORD = "confirmPassword",
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const emailParams = searchParams.get("email") || "";

  const isConformPasswordPage = page === ForgotPasswordPages.CONFIRM_PASSWORD;

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) || isConformPasswordPage
          ? null
          : "Invalid email",
      code: (value) =>
        value.length < 6 && isConformPasswordPage
          ? "code must have at least 6 letters"
          : null,
      password: (value) =>
        value.length < 8 && isConformPasswordPage
          ? "Password must have at least 8 letters"
          : null,
      confirmPassword: (value, values) =>
        value !== values.password && isConformPasswordPage
          ? "Passwords did not match"
          : null,
    },
  });

  const handleSubmit = async (values: {
    email: string;
    code: string;
    password: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    if (!isConformPasswordPage) {
      const request: IGetForgotPasswordCodeRequest = {
        email: values.email,
      };
      try {
        const forgotPasswordCodeData = await commonRequest(
          ApiMethod.POST,
          ApiEndpoint.FORGOT_PASSWORD_CODE,
          request,
        );
        if (forgotPasswordCodeData) {
          setLoading(false);
          navigate(
            `${location.pathname}?page=${ForgotPasswordPages.CONFIRM_PASSWORD}&&email=${values.email}`,
          );
        }
      } catch (err: any) {
        setLoading(false);
        setError(err.Error);
        console.log("get forgot password code API:", err);
      }
    }

    if (isConformPasswordPage) {
      const request: ISetPasswordRequest = {
        email: emailParams,
        code: values.code,
        password: values.confirmPassword,
      };
      try {
        const setPasswordData = await commonRequest(
          ApiMethod.POST,
          ApiEndpoint.SET_PASSWORD,
          request,
        );
        if (setPasswordData) {
          setLoading(false);
          navigate("/login");
        }
      } catch (err: any) {
        setLoading(false);
        setError(err.Error);
        console.log("Set password API:", err);
      }
    }
  };

  return (
    <div className="flex h-screen w-full">
      <div className="flex max-sm:hidden h-full w-1/2">
        <img
          src={forgotPasswordSideImg}
          alt="sideImg"
          className="h-full w-full lg:object-cover"
        />
      </div>
      <div className="max-sm:w-full content-center overflow-y-scroll sm:w-1/2">
        <div className="flex w-full flex-col items-center justify-center p-12">
          <div className="flex w-full flex-col items-center max-w-[500px]">
            <img src={npsLogo} alt="logo" width={200} />
            <div className="my-6 text-center font-semibold text-[32px]">
              Forgot Password
            </div>
            <form
              className="flex w-full flex-col gap-5"
              onSubmit={form.onSubmit(handleSubmit)}
            >
              {!isConformPasswordPage && (
                <TextInput
                  radius="md"
                  label="Email"
                  placeholder="Enter your email"
                  key={form.key("email")}
                  {...form.getInputProps("email")}
                  classNames={{
                    label: "mb-3 text-sm font-medium",
                    input:
                      "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                  }}
                />
              )}
              {isConformPasswordPage && (
                <>
                  <div>
                    <div className="mb-3 text-sm font-semibold">Code</div>
                    <PinInput
                      size="xl"
                      length={6}
                      placeholder=""
                      type="number"
                      classNames={{
                        input: "rounded-lg bg-whisper-gray text-gray-500",
                      }}
                      {...form.getInputProps("code")}
                    />
                    <div className="mt-2 text-xs text-red-400">
                      {form?.errors?.code}
                    </div>
                  </div>
                  <PasswordInput
                    radius="md"
                    label="Password"
                    placeholder="Password"
                    key={form.key("password")}
                    {...form.getInputProps("password")}
                    classNames={{
                      label: "mb-3 text-sm font-medium",
                      input:
                        "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                    }}
                  />
                  <PasswordInput
                    radius="md"
                    label="Confirm password"
                    placeholder="Confirm password"
                    key={form.key("confirmPassword")}
                    {...form.getInputProps("confirmPassword")}
                    classNames={{
                      label: "mb-3 text-sm font-medium",
                      input:
                        "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                    }}
                  />
                </>
              )}
              <Button
                loading={loading}
                disabled={loading}
                type="submit"
                size="lg"
                radius="lg"
                className="w-full h-[50px]"
              >
                {isConformPasswordPage ? "Submit" : "Request reset link"}
              </Button>
              <div className="text-center text-sm font-medium text-gray-400">
                Back To{" "}
                <span
                  className="cursor-pointer text-blue-500"
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </span>
              </div>
            </form>
            {error && (
              <Alert
                title="Something went wrong!"
                radius="md"
                color="red"
                className="mt-5 w-full"
              >
                {error}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
