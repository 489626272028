import React, { useEffect, useState } from "react";
import {
  BackgroundImage,
  Text,
  Button,
  Tooltip,
  Select,
  Progress,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import DeleteConfirmationModal from "components/ConfirmationModals/DeleteConfirmationModal";
import SignOutConfirmationModal from "components/ConfirmationModals/SignOutConfirmationModal";
import ProfileBackgroundImage from "assets/images/profileImages/profileBackgroundImage.png";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "context/App/AppContext";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { commonRequest } from "services/api/commonRequest";
import { AppActionsEnum } from "context/App/AppContextValues";
import ProfileImage from "components/ProfileImage/ProfileImage";
import { IUserDetailsResponse } from "data/types/response";
import ProfileUpdateModal from "./components/ProfileUpdateModal";
import { DateRange, DateRangeOptions, ProfileDetail } from "./Profile.type";
import TotalPieChart from "./components/pieChart";
import MyUsagesModal from "./components/MyUsagesModal";
import AvailableCreditModal from "./components/AvaliableCreditModal";
import "./Profile.css";

export const getISOFormattedDate = (date: string) => {
  const currentDate = new Date();
  let startDate = new Date();

  if (date === DateRange.TWENTY_FOUR_HOURS) {
    startDate.setDate(currentDate.getDate() - 1);
  } else if (date === DateRange.SEVEN_DAYS) {
    startDate.setDate(currentDate.getDate() - 7);
  } else if (date === DateRange.THIRTY_DAYS) {
    startDate.setDate(currentDate.getDate() - 30);
  }

  const endDate = currentDate.toISOString();
  const formattedStartDate = startDate.toISOString();

  return { endDate, formattedStartDate };
};

const Profile = () => {
  const [dropdownOpened, { toggle }] = useDisclosure();
  const [profileData, setProfileData] = useState<Array<ProfileDetail>>([
    {
      "First Name": "",
      "Last Name": "",
      Email: "",
      Phone: "",
    },
  ]);

  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const [isMyUsagesModalOpen, setISMyUsagesModalOpen] =
    useState<boolean>(false);
  const [isAvailableCreditModalOpen, setIsAvailableCreditModalOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { app, dispatch } = useAppContext();
  const { userDetails } = app;
  const creditRange = (userDetails?.Credits * 100) / userDetails?.TotalCredits;

  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    app?.selectedDateRange,
  );

  useEffect(() => {
    const data = [
      {
        "First Name": userDetails?.Name?.FirstName,
        "Last Name": userDetails?.Name?.LastName,
        Email: userDetails?.Email,
        Phone: userDetails?.MobileNumber,
      },
    ];
    setProfileData(data);
  }, [userDetails]);

  const handleHistoryChange = async (date: string) => {
    const { endDate, formattedStartDate } = getISOFormattedDate(date);
    setSelectedDateRange(date);
    setIsLoading(true);
    dispatch({
      type: AppActionsEnum.SET_SELECTED_DATE_RANGE,
      payload: {
        selectedDateRange: date,
      },
    });
    try {
      const endpoint =
        `${ApiEndpoint.GET_PROFILE}?start_date=${formattedStartDate}&end_date=${endDate}` as ApiEndpoint;
      const data = await commonRequest(ApiMethod.GET, endpoint, null, true);
      if (data) {
        dispatch({
          type: AppActionsEnum.SET_PROFILE,
          payload: {
            userDetails: data as IUserDetailsResponse,
          },
        });
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleHistoryChange(date);
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pb-24 min-[334px]:pb-[88px] min-[540px]:pb-[60px]">
      <div className="p-4">
        <div>
          <BackgroundImage
            src={ProfileBackgroundImage}
            mih="182px"
            className="rounded-[18px]"
          >
            <div className="flex justify-items-start items-end w-full min-h-[182px] gap-3 p-5">
              <div>
                <ProfileImage
                  userDetails={userDetails}
                  className="!w-[55px] !h-[55px] !rounded-full !object-cover !text-3xl"
                />
              </div>
              <div className="flex flex-col h-[55px] justify-center">
                <Text c="white" className="text-base" fw={600}>
                  {userDetails?.Name?.FirstName} {userDetails?.Name?.LastName}
                </Text>
                <Text c="white" className="text-xs leading-normal" fw={500}>
                  {userDetails?.Credits}{" "}
                  {userDetails?.Credits <= 1
                    ? "credit remaining"
                    : "credits remaining"}
                </Text>
              </div>
            </div>
          </BackgroundImage>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-5 pt-7">
          <div className="border border-solid border-[#E8E8E8] rounded-2xl p-5 w-full gap-5">
            <div className="flex justify-between">
              <div className="text-lg font-semibold">My Profile</div>
              <Button
                className="w-20 h-[33px] px-3.5 py-1.5 !bg-stone-100 rounded-[100px] justify-center items-center gap-2 inline-flex"
                color="gray"
                onClick={() => setIsProfileModalOpen(true)}
              >
                <div className="text-zinc-800 text-sm font-normal">Change</div>
              </Button>
            </div>
            <div className="w-full pt-5">
              {profileData.length > 0 &&
                profileData.map((profile, index) => (
                  <div
                    key={index}
                    className="grid sm:grid-cols-1 md:grid-cols-2 gap-5"
                  >
                    {Object.entries(profile).map(([key, value]) => (
                      <div key={key} className="w-full flex flex-col flex-wrap">
                        <Text c="gray" className="text-sm" fw={400}>
                          {key}
                        </Text>
                        <Tooltip withArrow label={value}>
                          <Text className="w-full text-sm truncate" fw={400}>
                            {value}
                          </Text>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          <div className="border relative border-solid border-[#E8E8E8] rounded-2xl p-5 w-full gap-5">
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ children: <Loader color="blue" /> }}
            />
            <div className="flex justify-between">
              <div className="text-lg font-semibold">My Usage</div>
              <div className="max-w-[85px]" onClick={toggle}>
                <Select
                  radius={8}
                  allowDeselect={false}
                  defaultValue={DateRange.THIRTY_DAYS}
                  value={selectedDateRange}
                  data={DateRangeOptions}
                  onChange={(val) => handleHistoryChange(val as string)}
                  rightSection={
                    dropdownOpened ? (
                      <SvgIcon icon="UP_ARROW" />
                    ) : (
                      <SvgIcon icon="DOWN_ARROW" />
                    )
                  }
                  dropdownOpened={dropdownOpened}
                />
              </div>
            </div>
            <div className="w-full pt-5">
              <TotalPieChart />
            </div>
          </div>
          <div className="border border-solid border-[#E8E8E8] rounded-2xl p-5 w-full">
            <div className="flex justify-between items-center max-xl:flex-wrap gap-5 gap-x-14">
              <div className="w-full">
                <div className="text-lg font-semibold">Available Credits</div>
                <div className="w-full pt-3">
                  <div className="flex gap-5">
                    <div>
                      <SvgIcon icon="CREDITS_ICON" />
                    </div>
                    <div className="flex flex-col w-full gap-1">
                      <div className="flex w-full justify-between">
                        <div>Total available credits</div>
                        <div>{userDetails?.Credits}</div>
                      </div>
                      <div className="w-full">
                        <Progress value={creditRange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                fullWidth
                classNames={{ label: "!overflow-visible" }}
                className="font-bold text-sm max-w-28 rounded-[10px] h-10 !overflow-visible"
                onClick={() => navigate("/usages/buy-credits")}
              >
                Buy Credits
              </Button>
            </div>
          </div>
          <div className="border border-solid border-[#E8E8E8] rounded-2xl p-5 h-full w-full gap-5">
            <div className="">
              <div>
                <div className="text-lg font-semibold">Exit</div>
                <div className="text-xs font-normal text-slate-500">
                  Choose to securely sign out or delete your account.
                </div>
              </div>
              <div className="flex h-full pt-3 gap-3 items-end flex-wrap justify-end">
                <Button
                  onClick={() => setIsSignOutModalOpen(true)}
                  className="px-[30px] h-[42px] py-[11px] rounded-[10px] font-bold border border-gray-100 max-lg:w-full"
                  variant="outline"
                  color="#808080"
                >
                  Sign out
                </Button>
                <Button
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="px-[30px] h-[42px] py-[11px] rounded-[10px] font-bold border border-gray-100 max-lg:w-full"
                  variant="outline"
                  color="#808080"
                >
                  Delete your account
                </Button>
              </div>
            </div>
          </div>
          <SignOutConfirmationModal
            isSignOutModalOpen={isSignOutModalOpen}
            setIsSignOutModalOpen={setIsSignOutModalOpen}
          />
          <DeleteConfirmationModal
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
          />
          {isProfileModalOpen && (
            <ProfileUpdateModal
              setIsProfileModalOpen={setIsProfileModalOpen}
              isProfileModalOpen={isProfileModalOpen}
              setProfileData={setProfileData}
              profileData={profileData}
            />
          )}
          <MyUsagesModal
            setISMyUsagesModalOpen={setISMyUsagesModalOpen}
            isMyUsagesModalOpen={isMyUsagesModalOpen}
          />
          <AvailableCreditModal
            setIsAvailableCreditModalOpen={setIsAvailableCreditModalOpen}
            isAvailableCreditModalOpen={isAvailableCreditModalOpen}
          />
        </div>
      </div>
    </div>
  );
};
export default Profile;
