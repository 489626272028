import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "@mantine/core";
import { AuthActionsEnum } from "context/Auth/AuthContextValues";
import { LocalStorageKeys } from "data/constants/storage.constants";
import { useAuthContext } from "context/Auth/AuthContext";
import SuccessModal from "./SuccessModal";

interface ISignOutConfirmationModalProps {
  isSignOutModalOpen: boolean;
  setIsSignOutModalOpen: Dispatch<SetStateAction<boolean>>;
}

const SignOutConfirmationModal = ({
  isSignOutModalOpen,
  setIsSignOutModalOpen,
}: ISignOutConfirmationModalProps) => {
  const { dispatch } = useAuthContext();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleUserSignOut = () => {
    setIsSignOutModalOpen(false);
    setIsSuccessModalOpen(true);

    setTimeout(() => {
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
      setIsSuccessModalOpen(false);
      dispatch({
        type: AuthActionsEnum.SET_IS_LOGGED_IN,
        payload: {
          isLoggedIn: false,
        },
      });
    }, 1000);
  };

  return (
    <>
      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        onCloseFunction={() => setIsSuccessModalOpen(false)}
        successMessage="Your account has been successfully signout."
      />
      <Modal
        classNames={{
          title: "text-lg font-medium",
          close: "font-medium text-black",
          body: "p-5 pt-0",
          content: "rounded-xl",
          header: "p-5 pb-2.5",
        }}
        withCloseButton={false}
        size={340}
        centered
        opened={isSignOutModalOpen}
        onClose={() => setIsSignOutModalOpen(false)}
        title="Are you leaving?"
      >
        <p>Are you sure you want to sign out?</p>
        <div className="flex gap-3.5 pt-5 max-[360px]:flex-wrap-reverse">
          <Button
            onClick={() => setIsSignOutModalOpen(false)}
            classNames={{ root: "rounded-[10px] w-full h-10" }}
            variant="outline"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUserSignOut}
            classNames={{ root: "rounded-[10px] w-full h-10 bg-blue-500/90" }}
          >
            Sign Out
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SignOutConfirmationModal;
