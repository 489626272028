import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Button,
  Divider,
  Checkbox,
  Alert,
  LoadingOverlay,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Icon, IconOptions } from "components/Icon/Icon";
import npsLogo from "assets/images/npsFullLogo.svg";
import signUpSideImg from "assets/images/signUpBgImage.png";
import { IGoogleSignInRequest, ISignUpRequest } from "data/types/request";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  getSignInWithGoogleLink,
  SIGNUP_REDIRECT_URI,
} from "data/constants/api.constants";
import { ISignInResponse } from "data/types/response";
import { AuthActionsEnum } from "context/Auth/AuthContextValues";
import { useAuthContext } from "context/Auth/AuthContext";
import useGetUserDetails from "hooks/useGetUserDetails";
import { LocalStorageKeys } from "data/constants/storage.constants";

const SignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [searchParams] = useSearchParams();

  const { auth, dispatch } = useAuthContext();

  const authCode = searchParams.get("code") || "";
  const errorDescription = searchParams.get("error_description") || "";
  const { getUserDetails } = useGetUserDetails();

  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    setLoadingPage(true);
    const request: IGoogleSignInRequest = {
      code: authCode,
      redirecturi: SIGNUP_REDIRECT_URI,
    };

    try {
      const signInData = (await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.SIGN_IN_WITH_GOOGLE,
        request,
      )) as ISignInResponse;
      if (signInData) {
        dispatch({
          type: AuthActionsEnum.SET_IS_LOGGED_IN,
          payload: {
            isLoggedIn: true,
          },
        });
        localStorage.setItem(
          LocalStorageKeys.ACCESS_TOKEN,
          String(signInData.AccessToken),
        );
        localStorage.setItem(
          LocalStorageKeys.REFRESH_TOKEN,
          String(signInData.RefreshToken),
        );
        await getUserDetails();
      }
    } catch (err: any) {
      console.log("SignIn google API:", err);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    if (
      errorDescription &&
      errorDescription.includes("same email was created")
    ) {
      notifications.show({
        color: "red",
        title: "SignUp with google",
        message: "Account with the same email was created",
        autoClose: 5000,
      });
    }
    if (authCode && !auth.isLoggedIn) {
      signInWithGoogle();
    }
    // eslint-disable-next-line
  }, []);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsAndPolicy: false,
    },

    validate: {
      firstName: (value) =>
        value.length < 2 ? "First name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Last name must have at least 2 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length < 8 ? "Password must have at least 8 letters" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
      termsAndPolicy: (value) =>
        value ? null : "Please accept all terms and privacy policy",
    },
  });

  const submitSignUp = async (value: (typeof form)["values"]) => {
    setLoading(true);
    const request: ISignUpRequest = {
      firstname: value.firstName,
      lastname: value.lastName,
      email: value.email,
      password: value.confirmPassword,
    };

    try {
      const signUpData = await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.SIGNUP,
        request,
      );
      if (signUpData) {
        setLoading(false);
        navigate(`/verificationcode?email=${request.email}`);
      }
    } catch (err: any) {
      setLoading(false);
      setError(err?.Error);
      console.log("SignUp API:", err);
    }
  };

  return (
    <div className="flex h-screen w-full">
      <LoadingOverlay
        visible={loadingPage}
        classNames={{ loader: "text-2xl font-bold" }}
        loaderProps={{
          children: "Signing in, please wait...",
        }}
      />
      <div className="flex max-sm:hidden h-full w-1/2">
        <img
          src={signUpSideImg}
          alt="sideImg"
          className="h-full w-full lg:object-cover"
        />
      </div>
      <div className="max-sm:w-full content-center overflow-y-scroll sm:w-1/2">
        <div className="flex w-full flex-col items-center justify-center p-12">
          <div className="flex w-full flex-col items-center max-w-[500px]">
            <div className="flex items-end gap-1 max-sm:hidden">
              <img src={npsLogo} alt="logo" width={200} />
              <div className="text-[7.55px] uppercase pb-1">beta</div>
            </div>
            <div className="my-6 text-center font-semibold text-[32px]">
              Sign up with NPS
            </div>
            <form
              className="flex w-full flex-col gap-5"
              onSubmit={form.onSubmit(submitSignUp)}
            >
              <TextInput
                radius="md"
                label="First name"
                placeholder="Enter your First name"
                key={form.key("firstName")}
                {...form.getInputProps("firstName")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                }}
              />
              <TextInput
                radius="md"
                label="Last name"
                placeholder="Enter your Last name"
                key={form.key("lastName")}
                {...form.getInputProps("lastName")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                }}
              />
              <TextInput
                radius="md"
                label="Email"
                placeholder="Enter your email"
                key={form.key("email")}
                {...form.getInputProps("email")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                }}
              />
              <PasswordInput
                radius="md"
                label="Password"
                placeholder="Password"
                key={form.key("password")}
                {...form.getInputProps("password")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                }}
              />
              <div>
                <PasswordInput
                  radius="md"
                  label="Confirm password"
                  placeholder="Confirm password"
                  key={form.key("confirmPassword")}
                  {...form.getInputProps("confirmPassword")}
                  classNames={{
                    label: "mb-3 text-sm font-medium",
                    input:
                      "bg-whisper-gray text-gray-500 h-[54px] border shadow border-gray-200",
                  }}
                />
                <Checkbox
                  mt="md"
                  error={form.errors?.["termsAndPolicy"]}
                  label="I Accept All Terms and Privacy Policy"
                  classNames={{
                    label: "text-sm text-gray-500",
                  }}
                  key={form.key("termsAndPolicy")}
                  {...form.getInputProps("termsAndPolicy", {
                    type: "checkbox",
                  })}
                />
              </div>
              <Button
                loading={loading}
                disabled={loading}
                type="submit"
                size="lg"
                radius="lg"
                className="w-full h-[50px]"
              >
                Sign Up
              </Button>
            </form>
            {error && (
              <Alert
                title="Something went wrong!"
                radius="md"
                color="red"
                className="mt-5 w-full"
              >
                {error}
              </Alert>
            )}
            <Divider
              my="xl"
              label="or"
              labelPosition="center"
              className="w-full"
              classNames={{ label: "text-lg" }}
            />
            <div className="flex w-full flex-col items-center gap-6">
              <Button
                variant="outline"
                color="gray"
                size="lg"
                radius="xl"
                className="w-full border-gray-300 text-black h-[50px]"
                onClick={() =>
                  window.open(
                    getSignInWithGoogleLink(SIGNUP_REDIRECT_URI),
                    "_self",
                  )
                }
              >
                <Icon option={IconOptions.GOOGLE} size={30} className="mr-2" />
                Sign Up with Google
              </Button>
              <div className="text-center text-sm font-medium text-gray-400 max-w-[300px]">
                Already have an account?{" "}
                <span
                  className="cursor-pointer text-blue-500"
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
