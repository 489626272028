import { DetailedHTMLProps, HTMLAttributes, ReactElement } from "react";

export interface IIcons {
  RIGHT_SIDE_ARROW: ReactElement;
  NPS_FILL_LOGO: ReactElement;
  NPS_LOGO: ReactElement;
  NPS_FULL_LOGO: ReactElement;
  DASHBOARD: ReactElement;
  CHART: ReactElement;
  PROFILE: ReactElement;
  BRAND: ReactElement;
  QUESTION_MARK: ReactElement;
  FLAVOR: ReactElement;
  PRODUCT: ReactElement;
  TYPE: ReactElement;
  SIZE: ReactElement;
  COLOR: ReactElement;
  LIKE: ReactElement;
  DISLIKE: ReactElement;
  COMMENT: ReactElement;
  COPY: ReactElement;
  SHARE: ReactElement;
  SEND: ReactElement;
  MIC: ReactElement;
  CROSS: ReactElement;
  UPLOAD: ReactElement;
  LEFT_SIDE_ARROW: ReactElement;
  SETTING: ReactElement;
  DELETE: ReactElement;
  EMAIL: ReactElement;
  SMS: ReactElement;
  CALL: ReactElement;
  LOCATION: ReactElement;
  LOCATION_BLUE: ReactElement;
  INSTAGRAM: ReactElement;
  FACEBOOK: ReactElement;
  TWITTER: ReactElement;
  YOUTUBE: ReactElement;
  META_DATA: ReactElement;
  CLASSIFICATION: ReactElement;
  ACCESSIBILITY: ReactElement;
  DATABASE: ReactElement;
  CHECK: ReactElement;
  SEND_FILL: ReactElement;
  MESSAGE: ReactElement;
  META_TEXT_ONE: ReactElement;
  META_TEXT_TWO: ReactElement;
  META_TEXT_THREE: ReactElement;
  COMMUNITY: ReactElement;
  DOWN_ARROW: ReactElement;
  UP_ARROW: ReactElement;
  SIGN_OUT: ReactElement;
  CREDITS_ICON: ReactElement;
  USAGES_ICON: ReactElement;
  CARD_LOGO: ReactElement;
  PLUS: ReactElement;
  SORT_ICON: ReactElement;
  RUPEE_ICON: ReactElement;
  CHARGE_ICON: ReactElement;
  ECONOMY_ICON: ReactElement;
  EXPIRY_ICON: ReactElement;
  SMILE: ReactElement;
  GENDER: ReactElement;
  ATTIRE: ReactElement;
  APPEARANCE: ReactElement;
  JEWELRY: ReactElement;
  DESIGN: ReactElement;
  MATERIAL: ReactElement;
  ACCESSORIES: ReactElement;
  LEGS: ReactElement;
  DIMENSIONS: ReactElement;
  QUANTITY: ReactElement;
  WEIGHT: ReactElement;
  PRICE: ReactElement;
  FEATURES: ReactElement;
  FINISH: ReactElement;
  PURPOSE: ReactElement;
  PACKAGING: ReactElement;
  INGREDIENTS: ReactElement;
  OCCASION: ReactElement;
  CLOSUER_TYPE: ReactElement;
  STYLE: ReactElement;
  MODEL: ReactElement;
  NPS_BW_LOGO: ReactElement;
  SLEEVE: ReactElement;
}

export const iconKeys = [
  "RIGHT_SIDE_ARROW",
  "NPS_FILL_LOGO",
  "NPS_LOGO",
  "NPS_FULL_LOGO",
  "DASHBOARD",
  "CHART",
  "PROFILE",
  "BRAND",
  "QUESTION_MARK",
  "FLAVOR",
  "PRODUCT",
  "TYPE",
  "SIZE",
  "COLOR",
  "LIKE",
  "DISLIKE",
  "COMMENT",
  "COPY",
  "SHARE",
  "SEND",
  "MIC",
  "CROSS",
  "UPLOAD",
  "LEFT_SIDE_ARROW",
  "SETTING",
  "DELETE",
  "EMAIL",
  "SMS",
  "CALL",
  "LOCATION",
  "LOCATION_BLUE",
  "INSTAGRAM",
  "FACEBOOK",
  "TWITTER",
  "YOUTUBE",
  "META_DATA",
  "CLASSIFICATION",
  "ACCESSIBILITY",
  "DATABASE",
  "CHECK",
  "SEND_FILL",
  "MESSAGE",
  "META_TEXT_ONE",
  "META_TEXT_TWO",
  "META_TEXT_THREE",
  "COMMUNITY",
  "DOWN_ARROW",
  "UP_ARROW",
  "SIGN_OUT",
  "CREDITS_ICON",
  "USAGES_ICON",
  "CARD_LOGO",
  "PLUS",
  "SORT_ICON",
  "RUPEE_ICON",
  "CHARGE_ICON",
  "ECONOMY_ICON",
  "EXPIRY_ICON",
  "SMILE",
  "GENDER",
  "ATTIRE",
  "APPEARANCE",
  "JEWELRY",
  "DESIGN",
  "MATERIAL",
  "ACCESSORIES",
  "LEGS",
  "DIMENSIONS",
  "QUANTITY",
  "WEIGHT",
  "PRICE",
  "FEATURES",
  "FINISH",
  "PURPOSE",
  "PACKAGING",
  "INGREDIENTS",
  "OCCASION",
  "CLOSUER_TYPE",
  "STYLE",
  "MODEL",
  "NPS_BW_LOGO",
  "SLEEVE",
];

export interface ISvgIconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  icon: keyof IIcons;
  fill?: string;
  key?: string;
}
