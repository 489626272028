import React, { useState } from "react";
import {
  Button,
  Loader,
  LoadingOverlay,
  Popover,
  Textarea,
  Tooltip,
} from "@mantine/core";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { notifications } from "@mantine/notifications";
import { ISendFeedbackRequest } from "data/types/request";

interface FeedbackProps {
  TaskId: string;
  OutputKey: string;
  ItemNumber: number;
}

const dislikeFeedbackOptions = [
  { label: "Don't like the style", value: "Don't like the style" },
  {
    label: "Didn't fully follow instructions",
    value: "Didn't fully follow instructions",
  },
  { label: "Innocent creation", value: "Innocent creation" },
  { label: "Don't like design", value: "Don't like design" },
  {
    label: "Classification algorithm is missing",
    value: "Classification algorithm is missing",
  },
  { label: "More...", value: "more" },
];

const likeFeedbackOptions = [
  { label: "Great style", value: "Great style" },
  {
    label: "Followed instructions perfectly",
    value: "Followed instructions perfectly",
  },
  { label: "Creative and innovative", value: "Creative and innovative" },
  { label: "Beautiful design", value: "Beautiful design" },
  {
    label: "Excellent classification algorithm",
    value: "Excellent classification algorithm",
  },
  { label: "More...", value: "more" },
];

const Feedback = ({ OutputKey, TaskId, ItemNumber }: FeedbackProps) => {
  // const [copyTooltip, setCopyTooltip] = useState("Copy");
  const [comment, setComment] = useState<string>("");
  // const [isShared, setIsShared] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const [isLikeFeedbackSelected, setIsLikeFeedbackSelected] = useState(false);
  const [isLikePopoverVisible, setIsLikePopoverVisible] = useState(false);
  const [isDislikeFeedbackSelected, setIsDislikeFeedbackSelected] =
    useState(false);
  const [isDislikePopoverVisible, setIsDislikePopoverVisible] = useState(false);
  const [isCommentFeedbackSelected, setIsCommentFeedbackSelected] =
    useState(false);

  // const handleCopyClick = () => {
  //   setCopyTooltip("Copied");
  //   setTimeout(() => {
  //     setCopyTooltip("Copy");
  //   }, 2000);
  // };

  const handleFeedback = async (Comment: string, like: number | null) => {
    if (!Comment) return;
    setIsLoading(true);
    const request: ISendFeedbackRequest = {
      OutputKey,
      TaskId,
      ItemNumber,
      Comment,
    };
    if (like !== null) {
      request.Like = like;
    }
    try {
      await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.TASK_FEEDBACK,
        request,
        true,
      );
      notifications.show({
        color: "green",
        message: "Thank You For Your Feedback!",
        autoClose: 2000,
      });
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleFeedback(Comment, like);
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      if (like !== null) {
        if (like) {
          setIsLikeFeedbackSelected(true);
          setTimeout(() => {
            setIsLikePopoverVisible(false);
          }, 2000);
        } else {
          setIsDislikeFeedbackSelected(true);
          setTimeout(() => {
            setIsDislikePopoverVisible(false);
          }, 2000);
        }
      } else {
        setIsCommentFeedbackSelected(true);
        setTimeout(() => {
          setIsCommentModalOpen(false);
        }, 2000);
      }
      setIsLoading(false);
      setComment("");
    }
  };

  const handleLikeClick = () => {
    setIsLikePopoverVisible(true);
    setIsLikeFeedbackSelected(false);
  };

  const handleDislikeClick = () => {
    setIsDislikePopoverVisible(true);
    setIsDislikeFeedbackSelected(false);
  };

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-x-2.5 relative">
        <Popover
          position="bottom-start"
          shadow="md"
          classNames={{
            dropdown: `!w-full max-sm:!left-0 ${!isLikeFeedbackSelected ? "!max-w-[525px]" : "!max-w-[230px]"}`,
          }}
          opened={isLikePopoverVisible}
          onClose={() => setIsLikePopoverVisible(false)}
        >
          <Popover.Target>
            <Tooltip
              withArrow
              arrowSize={10}
              classNames={{ tooltip: "bg-blue-100 text-black" }}
              label="Good Response"
            >
              <Button className="!bg-transparent p-0" onClick={handleLikeClick}>
                <SvgIcon
                  icon="LIKE"
                  className={`cursor-pointer ${isLikeFeedbackSelected && isLikePopoverVisible && "stroke-blue-500"}`}
                />
              </Button>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown>
            {!isLikeFeedbackSelected ? (
              <div className="my-0.5">
                <p className="font-semibold text-base pb-2">Tell Us More:</p>
                <LoadingOverlay
                  visible={isLoading}
                  loaderProps={{ children: <Loader color="blue" /> }}
                />
                <div className="flex flex-wrap gap-x-2 gap-y-1">
                  {likeFeedbackOptions.map((option) => (
                    <Button
                      key={option.label}
                      variant="outline"
                      onClick={() => handleFeedback(option.value, 1)}
                      className="block mb-1 !border-gray-100 !text-black w-fit p-2 !text-sm"
                    >
                      {option.label}
                    </Button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="p-1 text-sm text-center">
                <p>Thank You For Your Feedback!</p>
              </div>
            )}
          </Popover.Dropdown>
        </Popover>

        <Popover
          position="bottom-start"
          shadow="md"
          classNames={{
            dropdown: `!w-full max-sm:!left-0 ${!isDislikeFeedbackSelected ? "!max-w-[525px]" : "!max-w-[230px]"}`,
          }}
          opened={isDislikePopoverVisible}
          onClose={() => setIsDislikePopoverVisible(false)}
        >
          <Popover.Target>
            <Tooltip
              withArrow
              arrowSize={10}
              classNames={{ tooltip: "bg-blue-100 text-black" }}
              label="Bad Response"
            >
              <Button
                className="!bg-transparent p-0"
                onClick={handleDislikeClick}
              >
                <SvgIcon
                  icon="DISLIKE"
                  className={`cursor-pointer ${isDislikeFeedbackSelected && isDislikePopoverVisible && "stroke-blue-500"}`}
                />
              </Button>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown>
            {!isDislikeFeedbackSelected ? (
              <div className="my-0.5">
                <LoadingOverlay
                  visible={isLoading}
                  loaderProps={{ children: <Loader color="blue" /> }}
                />
                <p className="font-semibold text-base pb-2">Tell Us More:</p>
                <div className="flex flex-wrap gap-x-2 gap-y-1">
                  {dislikeFeedbackOptions.map((option) => (
                    <Button
                      key={option.label}
                      variant="outline"
                      onClick={() => handleFeedback(option.value, 0)}
                      className="block mb-1 !border-gray-100 !text-black w-fit p-2 !text-sm"
                    >
                      {option.label}
                    </Button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="p-1 text-sm text-center">
                <p>Thank You For Your Feedback!</p>
              </div>
            )}
          </Popover.Dropdown>
        </Popover>

        <Popover
          opened={isCommentModalOpen}
          onClose={() => setIsCommentModalOpen(false)}
          position="bottom-start"
          shadow="md"
          classNames={{
            dropdown: `max-sm:!left-0 !w-full ${isCommentFeedbackSelected ? "!max-w-[230px]" : "!max-w-[384px]"}`,
          }}
        >
          <Popover.Target>
            <Button
              onClick={() => setIsCommentModalOpen(true)}
              className="!bg-transparent p-0"
            >
              <SvgIcon
                icon="COMMENT"
                className="cursor-pointer"
                onClick={() => setIsCommentFeedbackSelected(false)}
              />
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            {!isCommentFeedbackSelected ? (
              <div>
                <p className="text-sm pb-2">Comment</p>
                <Textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  classNames={{
                    input:
                      "bg-gray-100/50 rounded-lg cursor-text !border-gray-200 h-24",
                  }}
                  placeholder="Write message here..."
                />
                <Button
                  loading={isLoading}
                  onClick={() => handleFeedback(comment, null)}
                  fullWidth
                  className="my-2"
                >
                  Send
                </Button>
              </div>
            ) : (
              <div className="p-1 text-sm text-center">
                <p>Thank You For Your Feedback!</p>
              </div>
            )}
          </Popover.Dropdown>
        </Popover>
        {/* <Tooltip
          withArrow
          arrowSize={10}
          classNames={{ tooltip: "bg-blue-100 text-black" }}
          label={copyTooltip}
        >
          <Button onClick={handleCopyClick} className="!bg-transparent p-0">
            <SvgIcon
              icon="COPY"
              className={`cursor-pointer ${copyTooltip === "Copy" ? "" : "stroke-blue-500"}  stroke-1`}
            />
          </Button>
        </Tooltip> */}
      </div>

      {/* <div className="flex items-center px-2">
        <SvgIcon
          icon="SHARE"
          onClick={() => {
            setIsShared(true);
            setTimeout(() => {
              setIsShared(false);
            }, 2000);
          }}
          className={`cursor-pointer ${!isShared ? "stroke-[#808080]" : "stroke-blue-500"}  stroke-1`}
        />
      </div> */}
    </div>
  );
};

export default Feedback;
