export interface ILocalStorageKeys {
  ID_PROVIDER: string;
  TOTAL_CREDIT_VISIBLE: string;
  ACCESS_TOKEN: string;
  REFRESH_TOKEN: string;
}

export const LocalStorageKeys: ILocalStorageKeys = {
  ID_PROVIDER: "IdProvider",
  TOTAL_CREDIT_VISIBLE: "isTotalCreditVisible",
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
};
