import React, { ReactNode, useEffect, useState } from "react";
import {
  AppShell,
  Burger,
  Divider,
  Group,
  RingProgress,
  Center,
  Progress,
  Menu,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import classNames from "classnames";
import { useAppContext } from "context/App/AppContext";
import { AiOutlinePieChart } from "react-icons/ai";
import { RiUserLine, RiSettingsLine } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "data/constants/storage.constants";
import SvgIcon from "../SvgIcon/SvgIcon";
import NavigationLink from "./components/NavigationLink";
import "./AppShellNavbar.css";
import SignOutConfirmationModal from "../ConfirmationModals/SignOutConfirmationModal";
import ProfileImage from "../ProfileImage/ProfileImage";

const AppShellNavbar: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [opened, { toggle }] = useDisclosure();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);
  const [isTotalCreditVisible, setIsTotalCreditVisible] = useState(false);
  const navigate = useNavigate();
  const { app } = useAppContext();
  const { userDetails } = app;
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const headerStyle = {
    right: "0px",
    cssText: "right: 0px !important",
  };
  const creditRange = (userDetails?.Credits * 100) / userDetails?.TotalCredits;

  useEffect(() => {
    const isTotalCreditVisible = JSON.parse(
      sessionStorage.getItem(LocalStorageKeys.TOTAL_CREDIT_VISIBLE) as string,
    );
    if (isTotalCreditVisible === null) {
      sessionStorage.setItem(
        LocalStorageKeys.TOTAL_CREDIT_VISIBLE,
        JSON.stringify(true),
      );
      setIsTotalCreditVisible(true);
    } else {
      setIsTotalCreditVisible(isTotalCreditVisible);
    }
  }, []);

  const handleDismissTotalCredit = () => {
    sessionStorage.setItem(
      LocalStorageKeys.TOTAL_CREDIT_VISIBLE,
      JSON.stringify(false),
    );
    setIsTotalCreditVisible(false);
  };

  const imageUsageHistory = userDetails?.UsageHistory?.find(
    (data) => data?.TaskType === "image-extraction",
  );
  const textUsageHistory = userDetails?.UsageHistory?.find(
    (data) => data?.TaskType === "text-extraction",
  );
  const imageCreditUsed = imageUsageHistory?.CreditsUsed || 0;
  const textCreditUsed = textUsageHistory?.CreditsUsed || 0;

  return (
    <AppShell
      layout="alt"
      header={{ height: 50 }}
      navbar={{
        width: isSideMenuOpen ? 250 : 65,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header style={headerStyle}>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <SignOutConfirmationModal
            setIsSignOutModalOpen={setIsSignOutModalOpen}
            isSignOutModalOpen={isSignOutModalOpen}
          />
          <div className="flex w-full justify-end items-center">
            <RingProgress
              size={40}
              thickness={4}
              sections={[{ value: creditRange, color: "blue" }]}
              label={
                <Center className="text-xs font-medium">
                  {userDetails?.Credits}
                </Center>
              }
            />
            <Divider
              orientation="vertical"
              classNames={{
                root: "w-5 my-5 rotate-90 border border-stone-300",
              }}
            />
            <Menu
              opened={isProfileOpen}
              onChange={() => {
                setIsProfileOpen(!isProfileOpen);
              }}
              classNames={{
                dropdown: "p-0 rounded-xl shadow shadow-gray-500/20 !w-56",
                item: "px-1.5 py-2.5",
              }}
            >
              <Menu.Target>
                <UnstyledButton>
                  <Group classNames={{ root: "gap-x-1 md:pr-3" }}>
                    <ProfileImage userDetails={userDetails} />
                    <div style={{ flex: 1 }}>
                      <Text
                        classNames={{ root: "ps-2.5" }}
                        className="text-sm max-[400px]:hidden"
                        fw={500}
                      >
                        {userDetails?.Name?.FirstName}{" "}
                        {userDetails?.Name?.LastName}
                      </Text>
                    </div>
                    {isProfileOpen ? (
                      <SvgIcon icon="UP_ARROW" />
                    ) : (
                      <SvgIcon icon="DOWN_ARROW" />
                    )}
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<ProfileImage userDetails={userDetails} />}
                >
                  <p>
                    {userDetails?.Name?.FirstName} {userDetails?.Name?.LastName}
                  </p>
                  <p className="text-xs text-blue-500">{userDetails?.Email}</p>
                </Menu.Item>
                <Menu.Divider />
                <div className="px-3">
                  <Menu.Item
                    onClick={() => navigate("/profile")}
                    leftSection={<SvgIcon icon="PROFILE" className="w-6 h-6" />}
                  >
                    My Profile
                  </Menu.Item>
                  <Menu.Item
                    classNames={{ item: "pl-2.5" }}
                    onClick={() => setIsSignOutModalOpen(true)}
                    leftSection={
                      <SvgIcon icon="SIGN_OUT" className="w-5 h-5" />
                    }
                  >
                    Sign Out
                  </Menu.Item>
                </div>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar>
        <Group>
          <Burger
            ms="14px"
            opened={opened}
            onClick={toggle}
            hiddenFrom="sm"
            size="sm"
          />
          <div
            className="flex items-end p-3.5 cursor-pointer"
            onClick={() => navigate("/")}
          >
            {isSideMenuOpen ? (
              <>
                <SvgIcon icon="NPS_FULL_LOGO" className="w-[150px]" />
                <div className="text-[7.55px] uppercase pb-1">beta</div>
              </>
            ) : (
              <SvgIcon icon="NPS_LOGO" className="h-8 w-8" />
            )}
          </div>
        </Group>
        {isSideMenuOpen ? (
          <SvgIcon
            onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
            icon="LEFT_SIDE_ARROW"
            className="absolute top-14 max-md:hidden cursor-pointer h-[20px] -right-[12px]"
          />
        ) : (
          <SvgIcon
            onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
            icon="RIGHT_SIDE_ARROW"
            className="absolute top-14 max-md:hidden cursor-pointer h-[20px] -right-[12px]"
          />
        )}

        <div
          className={`flex flex-col ${!isSideMenuOpen && "items-center"}  justify-between gap-3 mt-5 h-full`}
        >
          <div className={classNames(isSideMenuOpen ? "px-4" : "px-2")}>
            <NavigationLink
              to="/tools"
              Icon={RxDashboard}
              label="Tool"
              isSideMenuOpen={isSideMenuOpen}
              toggle={toggle}
            />
            <NavigationLink
              to="/usages"
              Icon={AiOutlinePieChart}
              label="My Usage"
              isSideMenuOpen={isSideMenuOpen}
              toggle={toggle}
            />
            <NavigationLink
              to="/profile"
              Icon={RiUserLine}
              label="Profile"
              toggle={toggle}
              isSideMenuOpen={isSideMenuOpen}
            />
          </div>
          <div className="pb-28">
            {isSideMenuOpen && isTotalCreditVisible && (
              <div
                className={classNames(
                  "bg-gray-100/50 border border-gray-200 rounded-2xl px-4 py-5",
                  isSideMenuOpen ? "mx-4" : "mx-2",
                )}
              >
                <p className="text-lg font-bold">Total Credits</p>
                <p className="text-xs">
                  You have already used {textCreditUsed + imageCreditUsed}{" "}
                  Credits.
                </p>
                <div className="flex items-center gap-x-2 py-4">
                  <Progress value={creditRange} className="w-full rounded" />
                  <span>{userDetails?.Credits}</span>
                </div>
                <span className="flex items-center gap-x-2 text-sm font-semibold">
                  <p
                    className="text-gray-400 cursor-pointer"
                    onClick={handleDismissTotalCredit}
                  >
                    Dismiss
                  </p>
                  <p
                    className="text-blue-500 cursor-pointer"
                    onClick={() => navigate("/usages")}
                  >
                    Get Credit
                  </p>
                </span>
              </div>
            )}
            <Divider my="md" />
            <div className="px-2">
              <NavigationLink
                to="/setting"
                Icon={RiSettingsLine}
                toggle={toggle}
                label="Settings"
                isSideMenuOpen={isSideMenuOpen}
              />
            </div>
          </div>
        </div>
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
      <AppShell.Footer style={headerStyle} py="md" px="xl">
        <div className="flex justify-between text-sm gap-2 max-sm:flex-wrap">
          <div>&#169; 2024 NPS. All rights reserved.</div>
          <div className="flex gap-3">
            <div>Terms & Conditions</div>
            <Divider size="sm" color="black" orientation="vertical" />
            <div>Privacy Policy</div>
            <Divider size="sm" color="black" orientation="vertical" />
            <div>Sitemap</div>
          </div>
        </div>
      </AppShell.Footer>
    </AppShell>
  );
};

export default AppShellNavbar;
