import HomeLayout from "components/HomeLayout/HomeLayout";

const PrivacyPolicy = () => {
  return (
    <HomeLayout>
      <>
        <div className="h-[300px] sm:h-[372px] w-full bg-[#ECF3FD] flex-col justify-center items-center gap-5 inline-flex">
          <div className="text-black text-4xl sm:text-[50px] font-bold sm:leading-[68px]">
            Privacy Policy
          </div>
          <div className="max-w-[760px] text-center text-zinc-800 text-[16px] px-10 sm:text-lg sm:leading-7">
            Welcome to Natural Product Sense (NPS), operated by Mohap AI
            Solutions Pvt Ltd in Pune, India. Our Privacy Policy governs how we
            handle your information when you use our Text and Image to Insights
            services.
          </div>
        </div>
        <div className="px-10 py-10 space-y-10 md:px-24">
          <div className="sm:pt-10">
            <div className="text-black text-3xl sm:text-[32px] font-bold sm:leading-10">
              Information We Collect
            </div>
            <div className="pt-5 space-y-2.5">
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Personal Information:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  When you sign up for our Services, we may collect personal
                  information such as your name, email address, and billing
                  information.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Usage Data:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We collect information about how you interact with our
                  Services, including your usage patterns, preferences, and
                  settings.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Content Data:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We may process text and images uploaded by you to provide our
                  data extraction and analysis services.
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold sm:leading-10">
              How We Use Your Information
            </div>
            <div className="pt-5 space-y-2.5">
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  To Provide and Maintain Our Services:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We use your information to deliver, maintain, and improve our
                  Services, including processing your data to extract insights
                  as requested.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  To Communicate with You:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We may use your contact information to send you
                  service-related communications, updates, and promotional
                  materials.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  For Research and Development:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We may use aggregated and anonymized data for research and
                  development purposes to improve our Services.
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold sm:leading-10">
              Sharing Your Information
            </div>
            <div className="pt-5 space-y-2.5">
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Service Providers:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We may share your information with trusted third-party service
                  providers who help us deliver our services, such as cloud
                  storage providers and analytics services.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Legal Requirements:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We may disclose your information when required by law or in
                  response to valid legal requests.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Privacy Assurance:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  Rest assured, we do not share your information with
                  third-party marketers or similar services.
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold leading-10">
              Security of Your Information
            </div>
            <div className="pt-5 text-zinc-800 text-lg leading-7">
              We prioritize the security of your information and implement
              appropriate technical and organizational measures to protect it
              against unauthorized access, alteration, disclosure, or
              destruction.
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold sm:leading-10">
              Your Choices and Rights
            </div>
            <div className="pt-5 space-y-2.5">
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Access and Update:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  You can access and update your personal information through
                  your account settings.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Data Retention:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  We retain your information for as long as necessary to fulfill
                  the purposes outlined in this Privacy Policy unless a longer
                  retention period is required or permitted by law.
                </span>
              </div>
              <div>
                <span className="text-zinc-800 text-lg font-bold">
                  Opt-Out:
                </span>
                <span className="text-zinc-800 text-lg leading-7">
                  {" "}
                  You can opt-out of receiving promotional communications from
                  us by following the unsubscribe instructions included in these
                  communications.
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold sm:leading-10">
              Children's Privacy
            </div>
            <div className="pt-5 text-zinc-800 text-lg leading-7">
              Our Services are not intended for children under the age of 13. We
              do not knowingly collect personal information from children under
              13. If you believe we have inadvertently collected such
              information, please contact us to delete it.
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold sm:leading-10">
              Changes to This Privacy Policy
            </div>
            <div className="pt-5 text-zinc-800 text-lg leading-7">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices and legal requirements. We will notify
              you of any changes by posting the updated Privacy Policy on our
              website.
            </div>
          </div>
          <div>
            <div className="text-black text-3xl sm:text-[32px] font-bold leading-10">
              Contact Us
            </div>
            <div className="pt-5 text-zinc-800 text-lg leading-7">
              If you have any questions about this Privacy Policy or our
              practices, please contact us at contact@mohap.ai
            </div>
            <div className="text-zinc-800 text-lg leading-7 pt-2.5">
              By using our Services, you agree to the collection and use of
              information as outlined in this Privacy Policy.
            </div>
          </div>
        </div>
      </>
    </HomeLayout>
  );
};

export default PrivacyPolicy;
