import React, { useState } from "react";
import { Button, Modal } from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { ISendVerificationCodeRequest } from "data/types/request";
import { commonRequest } from "services/api/commonRequest";
import { ApiEndpoint, ApiMethod } from "data/constants/api.constants";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { notifications } from "@mantine/notifications";

interface ResendCodeModalProps {
  opened: boolean;
  close: () => void;
}

const ResendCodeModal: React.FC<ResendCodeModalProps> = ({ opened, close }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email") || "";

  const handelResendCode = async () => {
    const request: ISendVerificationCodeRequest = {
      email: email,
    };
    try {
      const resendCodeData = await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.RESEND_VERIFICATION_CODE,
        request,
      );
      if (resendCodeData && "message" in resendCodeData) {
        notifications.show({
          color: "green",
          message: resendCodeData?.message,
          autoClose: 5000,
        });
        close();
      }
    } catch (err: any) {
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      size={350}
      classNames={{
        content: "rounded-xl",
        header: "absolute right-0 m-0",
        body: "p-5",
      }}
      opened={opened}
      onClose={close}
    >
      <div className="flex flex-col items-center gap-5">
        <div>
          <SvgIcon icon="EMAIL" />
        </div>
        <div>
          <div className="text-center text-lg	font-medium">
            Resend verification code
          </div>
          <div className="mt-3 px-2 text-center text-sm">
            We have just sent an email with a new verification code to{" "}
            {`${email}`}
          </div>
        </div>
        <div className="flex w-full gap-3">
          <Button size="lg" radius="lg" className="w-full" onClick={close}>
            Got it
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            size="lg"
            radius="lg"
            className="w-full"
            variant="outline"
            color="gray"
            onClick={handelResendCode}
          >
            Resend
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ResendCodeModal;
