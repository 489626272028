import { ActionMap } from "../context.helpers";

export interface IAuthContext {
  isLoggedIn: boolean;
}

export const AuthInitialState: IAuthContext = {
  isLoggedIn: false,
};

export enum AuthActionsEnum {
  SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN",
}

export type AuthActionsPayload = {
  [AuthActionsEnum.SET_IS_LOGGED_IN]: {
    isLoggedIn: boolean;
  };
};

export type AuthActions =
  ActionMap<AuthActionsPayload>[keyof ActionMap<AuthActionsPayload>];
