import React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "pages/Auth/SignIn/SignIn";
import SignUp from "pages/Auth/SignUp/SignUp";
import Home from "pages/Home/Home";
import ForgotPassword from "pages/Auth/ForgotPassword/ForgotPassword";
import { useAuthContext } from "context/Auth/AuthContext";
import VerificationCode from "pages/Auth/VerificationCode/VerificationCode";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import PrivateRoutes from "./PrivateRoutes";

export const AppRoutes = () => {
  const { auth } = useAuthContext();

  if (auth.isLoggedIn) {
    return <PrivateRoutes />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<SignIn />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="verificationcode" element={<VerificationCode />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};
