import { useState } from "react";
import { Alert, Button, PinInput } from "@mantine/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import signUpSideImg from "assets/images/signUpBgImage.png";
import npsLogo from "assets/images/npsFullLogo.svg";
import { IVerificationCodeRequest } from "data/types/request";
import { useDisclosure } from "@mantine/hooks";
import { commonRequest } from "services/api/commonRequest";
import { ApiEndpoint, ApiMethod } from "data/constants/api.constants";
import ResendCodeModal from "./component/ResendCodeModal";

const VerificationCode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [opened, { open, close }] = useDisclosure(false);

  const navigate = useNavigate();

  const email = searchParams.get("email") || "";

  const handleVerificationCode = async () => {
    if (!verificationCode) {
      setError("Enter verification code");
      return;
    }
    if (verificationCode.length < 6) {
      setError("Enter 6 digit verification code");
      return;
    }
    setError("");
    setLoading(true);
    const request: IVerificationCodeRequest = {
      email: email,
      code: verificationCode,
    };

    try {
      const verifyCodeData = await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.VERIFY_CODE,
        request,
      );
      if (verifyCodeData) {
        navigate("/login");
      }
    } catch (err: any) {
      setError(err?.Error);
      console.log("Verify Code API:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ResendCodeModal opened={opened} close={close} />
      <div className="flex h-screen w-full">
        <div className="flex max-sm:hidden h-full w-1/2">
          <img
            src={signUpSideImg}
            alt="sideImg"
            className="h-full w-full lg:object-cover"
          />
        </div>
        <div className="max-sm:w-full content-center overflow-y-scroll sm:w-1/2">
          <div className="flex w-full flex-col items-center justify-center p-12">
            <div className="flex w-full flex-col items-center max-w-[500px]">
              <img src={npsLogo} alt="logo" width={200} />
              <div className="my-6 text-center font-semibold text-[32px]">
                Enter Verification Code
              </div>
              <div className="mb-6 text-center text-base max-w-[300px]">
                Please enter the 6-digit verification code that was sent to your
                email
              </div>
              <div className="flex w-full flex-col items-center gap-6">
                <div>
                  <PinInput
                    size="xl"
                    length={6}
                    placeholder=""
                    type="number"
                    error={!!error}
                    classNames={{
                      input: "rounded-lg bg-whisper-gray text-gray-500",
                    }}
                    onChange={(value) => setVerificationCode(value)}
                  />
                </div>
                <Button
                  loading={loading}
                  disabled={loading}
                  size="lg"
                  radius="lg"
                  className="w-full h-[50px]"
                  onClick={handleVerificationCode}
                >
                  Continue
                </Button>
              </div>
              <div className="mt-8 text-center text-sm font-medium text-gray-400">
                Didn’t receive a code?{" "}
                <span className="cursor-pointer text-blue-500" onClick={open}>
                  Click to resend
                </span>
              </div>
              {error && (
                <Alert
                  title="Something went wrong!"
                  radius="md"
                  color="red"
                  className="mt-5 w-full"
                >
                  {error}
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
