import React from "react";
import classNames from "classnames";

const StepIndicator = ({ steps }: { steps: string[] }) => {
  return (
    <div className="flex flex-col items-start">
      {steps.map((step, index) => (
        <div key={index} className="relative flex items-center">
          <div
            className="relative flex items-center"
            style={{ paddingLeft: `${index * 20}px` }}
          >
            {index > 0 && (
              <div
                className={`absolute -top-3 h-[35px] w-[10px] border-dashed border-l border-b border-gray-400`}
                style={{ left: `${index * 20}px` }}
              />
            )}
            <div
              className={classNames(
                "rounded-lg p-2 mb-3",
                index !== 0
                  ? "border border-gray-200 border-solid text-gray-400 ml-2.5"
                  : "bg-stone-300/20",
              )}
            >
              <label className="block">{step}</label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
