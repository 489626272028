import { Accordion } from "@mantine/core";

const FrequentlyAskedQuestions = () => {
  return (
    <div
      className="grid md:grid-cols-2 gap-5 md:gap-20 p-10 md:p-24 scroll-mt-16"
      id="faq"
    >
      <div>
        <div className="text-3xl sm:text-4xl font-bold">
          Frequently Asked{" "}
          <span className="italic bg-gradient-to-r from-blue-500 to-orange-400 bg-clip-text text-transparent">
            Questions
          </span>
        </div>
        <div className="mt-5 sm:text-lg max-w-[530px] text-zinc-800 text-lg leading-7">
          Explore answers to common inquiries about our Text and Image to
          Insights services, simplifying your understanding and utilization of
          these powerful AI-driven features.
        </div>
      </div>
      <div>
        <Accordion
          chevronPosition="right"
          radius="md"
          variant="separated"
          classNames={{
            item: "bg-blue-50",
            label: "font-semibold text-base",
          }}
        >
          <Accordion.Item value="item-1">
            <Accordion.Control>What is NPS?</Accordion.Control>
            <Accordion.Panel>
              NPS (Natural Product Sense) is a unique Gen-AI assistant designed
              to provide human-like analytics. It helps in converting raw data
              into enriched and organized data, making it easier for you to gain
              valuable insights and make informed decisions.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="item-2">
            <Accordion.Control>
              What are the text formats used?
            </Accordion.Control>
            <Accordion.Panel>
              Supported Text Formats: We accept text in any raw format for data
              extraction. Whether you are processing a single document or
              performing batch processing, our system can handle various text
              formats efficiently to provide comprehensive insights.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="item-3">
            <Accordion.Control>
              What are the image formats used?
            </Accordion.Control>
            <Accordion.Panel>
              Supported Image Formats: Our service supports images in PNG, JPG,
              and WEBP formats, with a maximum file size of 3MB. This ensures
              high-quality image processing and accurate metadata extraction.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="item-4">
            <Accordion.Control>
              Can I get bulk data or images extracted?
            </Accordion.Control>
            <Accordion.Panel>
              Bulk Data and Image Extraction: Yes, our platform supports the
              extraction of large volumes of data and images. Whether you need
              to process bulk data for a project or regular operations, our
              system is equipped to handle it. Please try out this feature and
              connect with us for more detailed information and assistance.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="item-5">
            <Accordion.Control>
              Can I use image-to-prompt AI for free?
            </Accordion.Control>
            <Accordion.Panel>
              Free Usage with Initial Sign-Up Offer: Yes, you can use our
              image-to-prompt AI feature for free with our initial sign-up
              offer. Upon signing up, you will receive 100 credits, which can be
              used for both text and image extractions. This allows you to
              explore our services and experience the benefits without any
              initial cost.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
