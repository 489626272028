import { Button, Input } from "@mantine/core";
import React, { useState } from "react";
import PasswordChangeModal from "./PasswordChangeModal";

const PasswordTab = () => {
  const [isPwdModalOpen, setIsPwdModalOpen] = useState(false);

  return (
    <>
      <div className="rounded-[16px] border border-gray-200 p-4 space-y-[30px] max-w-[608px]">
        <div className="flex justify-between">
          <div className="text-lg	font-semibold">Password</div>
          <Button
            className="w-20 h-[33px] px-3.5 py-1.5 !bg-stone-100 rounded-[100px] justify-center items-center gap-2 inline-flex"
            color="gray"
            onClick={() => {
              setIsPwdModalOpen(true);
            }}
          >
            <div className="text-zinc-800 text-sm font-normal">Change</div>
          </Button>
        </div>
        <div>
          <Input.Wrapper
            label="Password"
            classNames={{ label: "text-zinc-500 text-sm" }}
          >
            <Input
              classNames={{ input: "!border-none p-0" }}
              type="password"
              readOnly={true}
              value="Hello#123"
            />
          </Input.Wrapper>
        </div>
      </div>
      <PasswordChangeModal
        isPwdModalOpen={isPwdModalOpen}
        setIsPwdModalOpen={setIsPwdModalOpen}
      />
    </>
  );
};

export default PasswordTab;
