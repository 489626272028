import React, { createContext, ReactNode, useContext, useReducer } from "react";
import {
  AppActions,
  AppActionsEnum,
  AppInitialState,
  IAppContext,
} from "./AppContextValues";

export const AppReducer = (
  state: IAppContext,
  action: AppActions,
): IAppContext => {
  const { type, payload } = action;

  if (type === AppActionsEnum.SET_CREDIT) {
    return {
      ...state,
      credit: payload.credit,
    };
  }
  if (type === AppActionsEnum.SET_PROFILE) {
    return {
      ...state,
      userDetails: payload.userDetails,
    };
  }
  if (type === AppActionsEnum.SET_SELECTED_DATE_RANGE) {
    return {
      ...state,
      selectedDateRange: payload.selectedDateRange,
    };
  }
  return state;
};

export interface IAppContextProps {
  app: IAppContext;
  dispatch: React.Dispatch<AppActions>;
}

const AppContext = createContext<IAppContextProps>({
  app: AppInitialState,
  dispatch: () => {},
});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [app, dispatch] = useReducer(AppReducer, AppInitialState);

  return (
    <AppContext.Provider value={{ app, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;

export const useAppContext = (): IAppContextProps => useContext(AppContext);
