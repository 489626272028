import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppShellNavbar from "components/AppShellNavbar/AppShellNavbar";
import Tools from "pages/Tools/Tools";
import Profile from "pages/Profile/Profile";
import Settings from "pages/Settings/Settings";
import MyUsages from "pages/MyUsages/MyUsages";
import BuyCredits from "pages/MyUsages/components/BuyCredits";
import Home from "pages/Home/Home";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/tools"
        element={
          <AppShellNavbar>
            <Tools />
          </AppShellNavbar>
        }
      />
      <Route
        path="/profile"
        element={
          <AppShellNavbar>
            <Profile />
          </AppShellNavbar>
        }
      />
      <Route
        path="/setting"
        element={
          <AppShellNavbar>
            <Settings />
          </AppShellNavbar>
        }
      />
      <Route
        path="/usages"
        element={
          <AppShellNavbar>
            <MyUsages />
          </AppShellNavbar>
        }
      />
      <Route
        path="/usages/buy-credits"
        element={
          <AppShellNavbar>
            <BuyCredits />
          </AppShellNavbar>
        }
      />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to="/tools" />} />
    </Routes>
  );
};

export default PrivateRoutes;
