import { FcGoogle } from "react-icons/fc";
import { GrLanguage } from "react-icons/gr";
import { FiArrowRight } from "react-icons/fi";
import { HiPlayCircle } from "react-icons/hi2";
import { GrAttachment } from "react-icons/gr";

export enum IconOptions {
  GOOGLE = "GOOGLE",
  LANGUAGE = "LANGUAGE",
  ARROW_RIGHT = "ARROW_RIGHT",
  PLAY_CIRCLE = "PLAY_CIRCLE",
  ATTACH = "ATTACH",
}

interface IconProps {
  option: IconOptions;
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

export const Icon: React.FC<IconProps> = ({
  size = 18,
  option,
  color = "white",
  className,
  onClick,
}) => {
  const extraProps: {
    size?: number;
    color?: string;
    className?: string;
    onClick?: () => void;
  } = {
    size,
    color,
    className,
    onClick,
  };

  switch (option) {
    case IconOptions.GOOGLE:
      return <FcGoogle {...extraProps} />;
    case IconOptions.LANGUAGE:
      return <GrLanguage {...extraProps} />;
    case IconOptions.ARROW_RIGHT:
      return <FiArrowRight {...extraProps} />;
    case IconOptions.PLAY_CIRCLE:
      return <HiPlayCircle {...extraProps} />;
    case IconOptions.ATTACH:
      return <GrAttachment {...extraProps} />;

    default:
      return null;
  }
};
