import { FileInput } from "@mantine/core";
import { Icon, IconOptions } from "components/Icon/Icon";
import SvgIcon from "components/SvgIcon/SvgIcon";

const ImageMetaGenerator = () => {
  return (
    <div className="grid max-lg:grid-cols-1 grid-cols-2 gap-10 md:gap-20 max-lg:px-12 px-24 scroll-mt-32" id="features">
      <div className="flex flex-col justify-center">
        <div className="text-3xl sm:text-4xl font-bold max-w-[577px]">
          What Can You Use the Text/ Image to{" "}
          <span>
            <span className="bg-gradient-to-r from-gray-400 to-orange-400 bg-clip-text text-transparent italic">
              Data Insights
            </span>
            <br />
            <span className="bg-gradient-to-r from-blue-500 to-orange-400 bg-clip-text text-transparent italic">
              {" "}
              Generator for?
            </span>
          </span>
        </div>
        <div className="mt-5 flex flex-col gap-3">
          <div className="flex gap-2">
            <SvgIcon icon="CHECK" />
            <div className="text-base sm:text-lg">
              Discover patterns or styles in your data
            </div>
          </div>
          <div className="flex gap-2">
            <SvgIcon icon="CHECK" />
            <div className="text-base sm:text-lg">
              Easily locate and identify brands, logos, or engravings
            </div>
          </div>
          <div className="flex gap-2">
            <SvgIcon icon="CHECK" />
            <div className="text-base sm:text-lg">
              Explore and learn about various products in the image
            </div>
          </div>
          <div className="flex gap-2">
            <SvgIcon icon="CHECK" />
            <div className="text-base sm:text-lg">
              Obtain thorough, annotated explanations of product texts …and much
              more
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-2xl bg-blue-50 p-7">
        <div className="h-full w-full rounded-xl bg-white px-4 py-5">
          <div className="grid max-lg:grid-cols-2 max-sm:grid-cols-1 grid-cols-4 gap-3 text-center">
            <div className="flex flex-col items-center gap-3 p-3">
              <div className="rounded-full border-2 border-solid border-gray-200 bg-gray-100 p-3">
                <SvgIcon icon="META_DATA" />
              </div>
              <div className="max-w-[100px] text-center text-zinc-800 text-sm font-medium">
                Unveil and Discover Insights
              </div>
            </div>
            <div className="flex flex-col items-center gap-3 p-3">
              <div className="rounded-full border-2 border-solid border-gray-200 bg-gray-100 p-3">
                <SvgIcon icon="CLASSIFICATION" />
              </div>
              <div className="max-w-[100px] text-center text-zinc-800 text-sm font-medium">
                Classify and Organize Data
              </div>
            </div>
            <div className="flex flex-col items-center gap-3 p-3">
              <div className="rounded-full border-2 border-solid border-gray-200 bg-gray-100 p-3">
                <SvgIcon icon="ACCESSIBILITY" />
              </div>
              <div className="max-w-[100px] text-center text-zinc-800 text-sm font-medium">
                Identify Context and Characteristics of your data
              </div>
            </div>
            <div className="flex flex-col items-center gap-3 p-3">
              <div className="rounded-full border-2 border-solid border-gray-200 bg-gray-100 p-3">
                <SvgIcon icon="DATABASE" />
              </div>
              <div className="max-w-[100px] text-center text-zinc-800 text-sm font-medium">
                Identify Patterns and Trends
              </div>
            </div>
          </div>
          <div className="mt-5">
            <FileInput
              readOnly
              size="lg"
              radius="md"
              className="max-sm:hidden"
              placeholder="Explore your data to reveal key insights."
              classNames={{ input: "bg-gray-100" }}
              leftSection={<Icon option={IconOptions.ATTACH} color="gray" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageMetaGenerator;
