export enum ApiEndpoint {
  SIGNUP = "dev/auth/signup",
  VERIFY_CODE = "dev/auth/verify-email",
  SIGN_IN = "dev/auth/signin",
  FORGOT_PASSWORD_CODE = "dev/auth/forgot-password?function=get-code",
  SET_PASSWORD = "dev/auth/forgot-password?function=set-password",
  RESEND_VERIFICATION_CODE = "dev/auth/resend-verificationcode",
  SIGN_IN_WITH_GOOGLE = "dev/auth/signin-google",
  TEXT_EXTRACTION = "dev/text-extraction",
  IMAGE_EXTRACTION = "dev/image-extraction",
  DELETE_USER = "dev/auth/delete-account",
  CHANGE_PASSWORD = "dev/auth/change-password",
  UPDATE_PROFILE = "dev/update-profile",
  GET_PROFILE = "dev/get-profile",
  GET_TRANSACTION = "dev/transaction/new-transaction",
  GET_CREDIT_HISTORY = "dev/credit-history",
  TASK_FEEDBACK = "dev/feedback",
}

export enum ApiMethod {
  GET = "get",
  POST = "post",
}

export const LOGIN_REDIRECT_URI = "https://nps.mohap.ai/login";
export const SIGNUP_REDIRECT_URI = "https://nps.mohap.ai/signup";
export const EXPIRED_TOKEN_ERROR = "The incoming token has expired";

export const getSignInWithGoogleLink = (uri: string) =>
  `https://accounts.google.com/o/oauth2/v2/auth?client_id=912148928396-1rfrk7kdhsaer9221u4m5faugc4a3pko.apps.googleusercontent.com&redirect_uri=${uri}&response_type=code&scope=openid+email+profile&state=npsauth&prompt=select_account`;
