import { notifications } from "@mantine/notifications";
import { AppActionsEnum } from "context/App/AppContextValues";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { useAppContext } from "context/App/AppContext";
import { IUserDetailsResponse } from "data/types/response";

const useGetUserDetails = () => {
  const { dispatch } = useAppContext();

  const getUserDetails = async () => {
    try {
      const data = await commonRequest(
        ApiMethod.GET,
        ApiEndpoint.GET_PROFILE,
        null,
        true,
      );
      if (data) {
        dispatch({
          type: AppActionsEnum.SET_PROFILE,
          payload: {
            userDetails: data as IUserDetailsResponse,
          },
        });
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await getUserDetails();
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    }
  };

  return { getUserDetails };
};

export default useGetUserDetails;
