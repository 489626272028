import { IUserDetailsResponse } from "data/types/response";
import { DateRange } from "pages/Profile/Profile.type";
import { ActionMap } from "../context.helpers";

export interface IAppContext {
  credit: { available: number; used: number };
  userDetails: IUserDetailsResponse;
  selectedDateRange: string;
}

const defaultUserDetails: IUserDetailsResponse = {
  UUID: "",
  Name: {
    FirstName: "",
    LastName: "",
  },
  Email: "",
  "Profile-Image": "",
  MobileNumber: "",
  CreatedDate: "",
  LastModifiedDate: "",
  TotalCredits: 0,
  RemainingCredits: 0,
  UsedCredits: 0,
  TextExtracts: 0,
  ImageExtracts: 0,
  LoginHistory: [],
  LastDayUsed: 0,
  Credits: 0,
  TextModuleUsed: 0,
  ImageModuleUsed: 0,
  PaymentHistory: [],
  LatestCreditUsed: {
    Text: 0,
    Image: 0,
  },
  EscrowCredits: 0,
  FirstName: "",
  LastName: "",
  Mobile: "",
  GuardrailsFlagged: 0,
  UsageHistory: [],
};

export const AppInitialState: IAppContext = {
  credit: { available: 0, used: 0 },
  userDetails: defaultUserDetails,
  selectedDateRange: DateRange.THIRTY_DAYS,
};

export enum AppActionsEnum {
  SET_CREDIT = "SET_CREDIT",
  SET_PROFILE = "SET_PROFILE",
  SET_SELECTED_DATE_RANGE = "SET_SELECTED_DATE_RANGE",
}

export type AppActionsPayload = {
  [AppActionsEnum.SET_CREDIT]: {
    credit: { available: number; used: number };
  };
  [AppActionsEnum.SET_PROFILE]: {
    userDetails: IUserDetailsResponse;
  };
  [AppActionsEnum.SET_SELECTED_DATE_RANGE]: {
    selectedDateRange: string;
  };
};

export type AppActions =
  ActionMap<AppActionsPayload>[keyof ActionMap<AppActionsPayload>];
