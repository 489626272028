import { AppRoutes } from "./routes/Routes";
import { useEffect } from "react";
import { useAuthContext } from "./context/Auth/AuthContext";
import { AuthActionsEnum } from "./context/Auth/AuthContextValues";
import "./App.css";
import { getRefreshAccessToken } from "./services/api/commonRequest";
import useGetUserDetails from "./hooks/useGetUserDetails";
import { LocalStorageKeys } from "./data/constants/storage.constants";

function App() {
  const { dispatch } = useAuthContext();
  const { getUserDetails } = useGetUserDetails();

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem(
      LocalStorageKeys.ACCESS_TOKEN,
    );

    const handleRefreshAccessToken = async () => {
      try {
        const tokenData = await getRefreshAccessToken();
        if (tokenData) {
          localStorage.setItem(
            LocalStorageKeys.ACCESS_TOKEN,
            String(tokenData.AccessToken),
          );
          dispatch({
            type: AuthActionsEnum.SET_IS_LOGGED_IN,
            payload: {
              isLoggedIn: true,
            },
          });
        }
      } catch (err) {
        console.log("getRefreshAccessToken", err);
      }
    };

    if (!isAuthenticated) {
      handleRefreshAccessToken();
    }

    if (isAuthenticated) {
      getUserDetails();
      dispatch({
        type: AuthActionsEnum.SET_IS_LOGGED_IN,
        payload: {
          isLoggedIn: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
