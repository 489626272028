import { useState } from "react";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { Button, Progress, Select } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "context/App/AppContext";
import { commonRequest } from "services/api/commonRequest";
import { notifications } from "@mantine/notifications";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import RequestSuccessModal from "./RequestSuccessModal";

const amountOptions = [
  { value: "100", label: "₹100" },
  { value: "200", label: "₹200" },
  { value: "300", label: "₹300" },
  { value: "400", label: "₹400" },
];

const BuyCredits = () => {
  const navigate = useNavigate();
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const { app } = useAppContext();
  const { userDetails } = app;
  const creditRange = (userDetails?.Credits * 100) / userDetails?.TotalCredits;
  const [selectedAmount, setSelectedAmount] = useState("100");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreditRequest = async () => {
    setIsLoading(true);
    try {
      const endpoint =
        `${ApiEndpoint.GET_TRANSACTION}?amount=${selectedAmount}&currency=INR` as ApiEndpoint;
      const data = await commonRequest(ApiMethod.GET, endpoint, null, true);
      if ("paymentPage" in data) {
        window.open(data.paymentPage as string, "_self");
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleCreditRequest();
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pb-24 min-[334px]:pb-[88px] min-[540px]:pb-[60px]">
      <RequestSuccessModal
        isRequestModalOpen={isRequestModalOpen}
        setIsRequestModalOpen={setIsRequestModalOpen}
      />
      <div className="rounded-[10px] border border-gray-200 p-5">
        <p className="text-black text-xl font-semibold">Buy Credits</p>
        <div className="p-5 mt-5 bg-neutral-50 rounded-[10px] border border-gray-200 max-[500px]:flex-wrap flex items-center gap-5 justify-between w-full">
          <div className="flex items-center gap-x-2.5 min-w-44">
            <SvgIcon icon="RUPEE_ICON" />
            <div>
              <p className="text-black text-base font-semibold">
                Available Credits
              </p>
              <p className="text-neutral-600 text-[10px]">
                Total usage in the past 30 days
              </p>
            </div>
          </div>
          <div className="w-full max-w-[610px] flex items-center gap-x-2.5">
            <Progress value={creditRange} className="w-full" />
            <div className="text-right text-zinc-800 text-xs font-medium">
              {userDetails?.Credits}
            </div>
          </div>
        </div>
        <div className="mt-5 grid grid-cols-4 gap-5 max-[1500px]:grid-cols-3 max-[1200px]:grid-cols-2 max-[900px]:grid-cols-1">
          <div className="w-full h-[115px] p-5 bg-neutral-50 rounded-2xl border border-gray-200">
            <div className="text-black text-sm pb-2">
              Select the amount to buy
            </div>
            <Select
              allowDeselect={false}
              placeholder="Please select the amount"
              classNames={{
                input: "bg-stone-100 text-zinc-500 border border-gray-200",
              }}
              defaultValue="100"
              onChange={(value) => setSelectedAmount(value as string)}
              data={amountOptions}
            />
          </div>
          <div className="w-full relative h-[115px] p-5 bg-neutral-50 rounded-2xl border border-gray-200">
            <p className="text-zinc-800 text-base font-medium pb-2.5">
              Your account will be charged
            </p>
            <p className="text-black text-sm font-semibold">
              ₹{selectedAmount}
            </p>
            <SvgIcon
              icon="CHARGE_ICON"
              className="flex justify-end w-full absolute right-2.5 bottom-2.5"
            />
          </div>
          <div className="w-full relative h-[115px] p-5 bg-neutral-50 rounded-2xl border border-gray-200">
            <p className="text-zinc-800 text-base font-medium pb-2.5">
              Your new credits balance will be
            </p>
            <p className="text-black text-sm font-semibold">
              {userDetails?.Credits + Number(selectedAmount)}
            </p>
            <SvgIcon
              icon="ECONOMY_ICON"
              className="flex justify-end w-full absolute right-2.5 bottom-2.5"
            />
          </div>
          <div className="w-full relative min-h-[115px] p-5 bg-neutral-50 rounded-2xl border border-gray-200">
            <p className="text-zinc-800 text-base font-medium pb-2.5">
              Redeem your credits
            </p>
            <p className="text-neutral-600 text-sm">
              New credits will enable{" "}
              {Math.ceil(userDetails?.Credits + Number(selectedAmount))} text
              extractions and{" "}
              {Math.ceil((userDetails?.Credits + Number(selectedAmount)) / 5)}{" "}
              image extractions.
            </p>
          </div>
        </div>
        <div className="mt-5 gap-3 flex justify-end max-[400px]:flex-wrap-reverse">
          <Button
            onClick={() => navigate("/usages")}
            variant="outline"
            className="w-[94px] max-[400px]:w-full h-[42px] !bg-white !text-zinc-500 text-sm font-semibold px-[30px] py-[11px] rounded-[10px] border border-gray-200"
          >
            Back
          </Button>
          <Button
            loading={isLoading}
            onClick={handleCreditRequest}
            className="h-[42px] max-[400px]:w-full px-[30px] py-[11px] bg-blue-500 rounded-[10px] text-white text-sm font-semibold"
          >
            Request for credits
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BuyCredits;
