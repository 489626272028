import React, { useEffect, useState } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Loader,
  Pagination,
} from "@mantine/core";
import SvgIcon from "components/SvgIcon/SvgIcon";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import PlaceholderImage from "assets/images/fakeItemImage.png";
import { commonRequest } from "services/api/commonRequest";
import { notifications } from "@mantine/notifications";

interface ICreditHistoryData {
  Search: string[];
  Type: string;
  Credits: number | string;
  Date: string;
}

const formatDate = (dateString: string) => {
  if (!dateString || dateString === "NA") return;
  const utcDate = new Date(dateString);

  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000,
  );

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options)
    .format(localDate)
    .replace(",", "");
};

const CreditHistoryTable = () => {
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [creditHistoryData, setCreditHistoryData] = useState<
    ICreditHistoryData[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const fetchCreditHistory = async (page: number) => {
    setLoading(true);
    try {
      const endpoint =
        `${ApiEndpoint.GET_CREDIT_HISTORY}?from=${(page - 1) * recordsPerPage + 1}&to=${page * recordsPerPage}&sort=${reverseSortDirection ? "descending" : "ascending"}` as ApiEndpoint;
      const data = await commonRequest(ApiMethod.GET, endpoint, null, true);
      if ("credit-history" in data) {
        const apiData = data as {
          "credit-history": ICreditHistoryData[];
          "total-count": number;
        };
        setTotal(apiData["total-count"]);
        setCreditHistoryData(apiData["credit-history"]);
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await fetchCreditHistory(currentPage);
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCreditHistory(currentPage);
    // eslint-disable-next-line
  }, [currentPage, reverseSortDirection]);

  const renderRows = () => {
    return creditHistoryData?.map((row, index) => (
      <Table.Tr key={index} className="even:bg-neutral-50 last:!bg-transparent">
        <Table.Td>
          {row.Type === "Image" ? (
            row?.Search[0]?.includes("image icon not available") ? (
              <img
                src={PlaceholderImage}
                alt="item"
                className="h-12 w-12 object-cover rounded-[5px]"
              />
            ) : (
              <img
                src={`data:image;base64,${row?.Search[0]?.replaceAll('"', "")}`}
                alt="item"
                className="h-12 w-12 object-cover rounded-[5px]"
              />
            )
          ) : (
            row?.Search[0]
          )}
        </Table.Td>
        <Table.Td
          style={{ color: row?.Credits > 0 ? "green" : "red" }}
          className="text-right"
        >
          {row?.Credits > 0 ? `+${row?.Credits}` : row?.Credits}
        </Table.Td>
        <Table.Td>{formatDate(row?.Date)}</Table.Td>
      </Table.Tr>
    ));
  };

  if (loading)
    return (
      <Loader
        color="blue"
        classNames={{ root: "flex justify-center w-full" }}
      />
    );
  return (
    <ScrollArea>
      {creditHistoryData?.length > 0 ? (
        <div className="rounded-3xl border border-solid border-blue-100">
          <Table
            classNames={{
              table: "",
              td: "py-3.5 px-5 text-zinc-500",
              th: "py-3.5 px-5",
            }}
          >
            <Table.Thead className="h-[70px] bg-blue-50">
              <Table.Tr>
                <Table.Th className="rounded-tl-3xl">
                  <Text className="text-black text-lg">Brief</Text>
                </Table.Th>
                <Table.Th className="text-right">
                  <Text className="text-black text-lg">Credits</Text>
                </Table.Th>
                <Table.Th className="rounded-tr-3xl">
                  <UnstyledButton
                    onClick={() =>
                      setReverseSortDirection(!reverseSortDirection)
                    }
                  >
                    <Group gap={2}>
                      <Text className="text-black text-lg">Date</Text>
                      <Center>
                        <SvgIcon icon="SORT_ICON" />
                      </Center>
                    </Group>
                  </UnstyledButton>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{renderRows()}</Table.Tbody>
          </Table>
        </div>
      ) : (
        <Text>Sorry, there's no credit history to display.</Text>
      )}
      <div className="mt-4 flex justify-end">
        <Pagination
          total={Math.ceil(total / recordsPerPage)}
          value={currentPage}
          onChange={setCurrentPage}
        />
      </div>
    </ScrollArea>
  );
};

export default CreditHistoryTable;
