import { Button } from "@mantine/core";
import { Icon, IconOptions } from "components/Icon/Icon";
import { useNavigate } from "react-router-dom";
import SideBannerImage from "assets/images/Dashboard/bannerSideImage.png";

const NPSWelcome = () => {
  const navigate = useNavigate();
  return (
    <div className="grid max-md:grid-cols-1 items-center justify-between grid-cols-2 gap-10 px-10 py-10 md:px-24">
      <div>
        <div className="mt-3 flex flex-col gap-5">
          <div
            style={{ lineHeight: "68px" }}
            className="sm:text-5xl text-4xl font-bold leading-16"
          >
            Transforming <br />{" "}
            <span className="bg-gradient-to-r from-blue-500 to-orange-400 bg-clip-text text-transparent">
              Images and Text{" "}
            </span>{" "}
            into <br /> Powerful Insights
          </div>
          <div className="text-xl max-w-[577px]">
            Welcome to Natural Product Sense, your solution for converting
            images and text into actionable data. Easily enhance your
            decision-making with our intuitive, data transformation platform.
          </div>
        </div>
        <div className="mt-8 flex flex-wrap items-center gap-5">
          <Button
            onClick={() => navigate("/login")}
            variant="filled"
            size="md"
            radius="md"
            className="px-[30px] !bg-neutral-800 rounded-[100px] gap-2.5 text-white text-base font-semibold"
          >
            Try it Now
          </Button>
          <div className="flex items-center gap-2">
            <Icon option={IconOptions.PLAY_CIRCLE} size={22} color="#4687EB" />
            <div className="text-base font-semibold">How it Works</div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <img src={SideBannerImage} alt="side banner" />
      </div>
    </div>
  );
};

export default NPSWelcome;
