import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context/Auth/AuthContext";
import SvgIcon from "../../../components/SvgIcon/SvgIcon";

const ExploreMoreTools = () => {
  const navigate = useNavigate();
  const { auth } = useAuthContext();

  return (
    <div
      style={{
        backgroundImage: `url(${require("assets/images/ExploreMoreBgImage.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: 450,
      }}
      className="flex flex-col items-center justify-center gap-5 px-10 py-10 md:px-24 md:py-20"
    >
      <SvgIcon icon="NPS_FILL_LOGO" />
      <div className="flex h-full flex-col items-center gap-3 text-white">
        <div className="text-center text-4xl font-bold">Explore More Tool</div>
        <div className="max-w-[720px] text-center text-white text-lg leading-7">
          Stay tuned for new features that will enhance this tool's
          capabilities, providing alerts on similar designs and more intelligent
          web searches to assist your daily tasks.
        </div>
      </div>
      <div>
        <Button
          variant="filled"
          size="lg"
          px="xl"
          radius="md"
          className="!bg-orange-400"
          onClick={() => navigate(auth.isLoggedIn ? "/tools" : "/login")}
        >
          Explore Tools
        </Button>
      </div>
    </div>
  );
};

export default ExploreMoreTools;
