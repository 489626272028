import React from "react";
import { Pie } from "@nivo/pie";
import { Button } from "@mantine/core";
import { useAppContext } from "context/App/AppContext";
import { useNavigate } from "react-router-dom";

const getPieColors = (id: string): string => {
  switch (id) {
    case "Image Search":
      return "#F98125";
    case "Text Search":
      return "#4687EB";
    default:
      return "#000000";
  }
};

const TotalPieChart = () => {
  const { app } = useAppContext();
  const navigate = useNavigate();
  const { userDetails } = app;

  const imageUsageHistory = userDetails?.UsageHistory?.find(
    (data) => data?.TaskType === "image-extraction",
  );
  const textUsageHistory = userDetails?.UsageHistory?.find(
    (data) => data?.TaskType === "text-extraction",
  );
  const imageCreditUsed = imageUsageHistory?.CreditsUsed || 0;
  const textCreditUsed = textUsageHistory?.CreditsUsed || 0;

  const data = [
    {
      id: "Image Search",
      label: "Image Search",
      value: imageCreditUsed,
    },
    {
      id: "Text Search",
      label: "Text Search",
      value: textCreditUsed,
    },
  ];

  return (
    <div className="flex items-center w-full gap-x-10 max-[400px]:grid max-lg:grid max-md:flex">
      <div className="relative w-[134px]">
        {imageCreditUsed + textCreditUsed === 0 ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-xs font-bold">
            <p className="text-[22px] font-medium">
              {imageCreditUsed + textCreditUsed}
            </p>
            <p className="text-[9px] text-gray-400 pt-0.5">Total Usage</p>
          </div>
        ) : (
          <>
            <Pie
              data={data}
              margin={{
                top: 10,
                right: 10,
                bottom: 10,
                left: 10,
              }}
              colors={({ id }) => getPieColors(id as string)}
              width={134}
              height={134}
              sortByValue={true}
              innerRadius={0.65}
              padAngle={0.7}
              activeOuterRadiusOffset={5}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={1}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={2}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
            />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-xs font-bold">
              <p className="text-[22px] font-medium">
                {imageCreditUsed + textCreditUsed}
              </p>
              <p className="text-[9px] text-gray-400 pt-0.5">Total Usage</p>
            </div>
          </>
        )}
      </div>

      <div className="flex w-full flex-wrap gap-x-10 gap-y-5 items-center justify-between">
        <div className="space-y-3 w-full max-w-[247px]">
          <div className="flex items-center bg-blue-300/10 rounded py-2 justify-between px-1.5">
            <div className="flex items-center gap-1">
              <p className="w-2.5 h-2.5 bg-orange-400 rounded-full" />
              <p className="font-medium text-xs">
                Image Search ({imageCreditUsed}{" "}
                {imageCreditUsed > 1 ? "Credits" : "Credit"})
              </p>
            </div>
            <p className="text-xs font-medium">
              {imageUsageHistory?.Count || 0}
            </p>
          </div>
          <div className="flex items-center bg-blue-300/10 rounded py-2 justify-between px-1.5">
            <div className="flex items-center gap-1">
              <p className="w-2.5 h-2.5 bg-blue-500 rounded-full" />
              <p className="font-medium text-xs">
                Text Search ({textCreditUsed}{" "}
                {textCreditUsed > 1 ? "Credits" : "Credit"})
              </p>
            </div>
            <p className="text-xs font-medium">
              {textUsageHistory?.Count || 0}
            </p>
          </div>
        </div>
        <Button
          fullWidth
          onClick={() => navigate("/usages")}
          className="font-bold text-base max-w-[155px] rounded-[10px] h-10"
        >
          View Summary
        </Button>
      </div>
    </div>
  );
};

export default TotalPieChart;
