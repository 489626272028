import { Button, Modal, Select } from "@mantine/core";
import React, { Dispatch, SetStateAction } from "react";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { useDisclosure } from "@mantine/hooks";
import { useAppContext } from "context/App/AppContext";
import { useNavigate } from "react-router-dom";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { commonRequest } from "services/api/commonRequest";
import { notifications } from "@mantine/notifications";
import { AppActionsEnum } from "context/App/AppContextValues";
import { IUserDetailsResponse } from "data/types/response";
import { getISOFormattedDate } from "../Profile";
import { DateRange, DateRangeOptions } from "../Profile.type";

interface IMyUsagesModalProps {
  isMyUsagesModalOpen: boolean;
  setISMyUsagesModalOpen: Dispatch<SetStateAction<boolean>>;
}

const MyUsagesModal = ({
  isMyUsagesModalOpen,
  setISMyUsagesModalOpen,
}: IMyUsagesModalProps) => {
  const [dropdownOpened, { toggle }] = useDisclosure();
  const { app, dispatch } = useAppContext();
  const { userDetails } = app;
  const navigate = useNavigate();

  const handleHistoryChange = async (currentDate: string) => {
    const { endDate, formattedStartDate } = getISOFormattedDate(currentDate);
    try {
      const endpoint =
        `${ApiEndpoint.GET_PROFILE}?start_date=${formattedStartDate}&end_date=${endDate}` as ApiEndpoint;
      const data = await commonRequest(ApiMethod.GET, endpoint, null, true);
      if (data) {
        dispatch({
          type: AppActionsEnum.SET_PROFILE,
          payload: {
            userDetails: data as IUserDetailsResponse,
          },
        });
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleHistoryChange(currentDate);
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    }
  };

  const imageUsageHistory = userDetails?.UsageHistory?.find(
    (data) => data?.TaskType === "image-extraction",
  );

  const textUsageHistory = userDetails?.UsageHistory?.find(
    (data) => data?.TaskType === "text-extraction",
  );

  return (
    <Modal
      classNames={{
        title: "w-full",
        body: "p-5 pt-0",
        header: "p-5 pb-2.5",
      }}
      withCloseButton={false}
      opened={isMyUsagesModalOpen}
      title={
        <div className="flex justify-between flex-wrap items-center gap-5">
          <div className="text-lg font-semibold">Credit Details</div>
          <div className="max-w-[85px]" onClick={toggle}>
            <Select
              radius={8}
              onChange={(val) => handleHistoryChange(val as string)}
              allowDeselect={false}
              defaultValue={DateRange.THIRTY_DAYS}
              data={DateRangeOptions}
              rightSection={
                dropdownOpened ? (
                  <SvgIcon icon="UP_ARROW" />
                ) : (
                  <SvgIcon icon="DOWN_ARROW" />
                )
              }
              dropdownOpened={dropdownOpened}
            />
          </div>
        </div>
      }
      centered
      size={647}
      radius={16}
      onClose={() => setISMyUsagesModalOpen(false)}
    >
      <div className="flex flex-wrap gap-3 items-center">
        <div className="w-[194.33px] h-[79px] px-3 py-5 bg-white rounded-lg border border-gray-200 justify-start items-center gap-3 inline-flex">
          <SvgIcon icon="USAGES_ICON" />
          <div className="flex-col justify-center items-start gap-1 inline-flex">
            <div className="text-zinc-500 text-[10px] font-normal leading-[13px]">
              Total Usages
            </div>
            <div className="text-black text-base font-medium leading-snug">
              {(imageUsageHistory?.CreditsUsed || 0) +
                (textUsageHistory?.CreditsUsed || 0)}
            </div>
          </div>
        </div>
        <div className="w-[194.33px] h-[79px] px-3 py-5 bg-white rounded-lg border border-gray-200 justify-start items-center gap-3 inline-flex">
          <SvgIcon icon="CREDITS_ICON" />
          <div className="flex-col justify-center items-start gap-1 inline-flex">
            <div className="text-zinc-500 text-[10px] font-normal leading-[13px]">
              Available Credits
            </div>
            <div className="text-black text-base font-medium leading-snug">
              {userDetails?.Credits}
            </div>
          </div>
        </div>
        <div className="space-y-3 w-full max-w-[194px]">
          <div className="flex items-center bg-blue-300/10 rounded py-2 justify-between px-1.5 w-[194px]">
            <div className="flex items-center gap-1">
              <p className="w-2 h-2 bg-orange-400 rounded-full" />
              <p className="font-medium text-[10px]">Image Search</p>
            </div>
            <p className="text-[10px] font-medium">
              {imageUsageHistory?.CreditsUsed}
            </p>
          </div>
          <div className="flex items-center bg-blue-300/10 rounded py-2 justify-between px-1.5 w-[194px]">
            <div className="flex items-center gap-1">
              <p className="w-2 h-2 bg-blue-500 rounded-full" />
              <p className="font-medium text-[10px]">Text Search</p>
            </div>
            <p className="text-[10px] font-medium">
              {textUsageHistory?.CreditsUsed}
            </p>
          </div>
        </div>
      </div>
      <Button
        onClick={() => {
          navigate("/usages");
        }}
        className="px-[30px] h-[42px] py-[11px] rounded-[10px] font-bold border border-gray-100 max-lg:w-full mt-4"
        variant="outline"
        color="#808080"
      >
        View Summary
      </Button>
    </Modal>
  );
};

export default MyUsagesModal;
