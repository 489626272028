import { Button } from "@mantine/core";
import SvgIcon from "components/SvgIcon/SvgIcon";
import CreditHistoryTable from "./components/CreditHistoryTable";
import { useNavigate } from "react-router-dom";

const MyUsages = () => {
  const navigate = useNavigate();

  return (
    <div className="pb-24 min-[334px]:pb-[88px] min-[540px]:pb-14">
      <div className="p-4 pb-0 space-y-5">
        <div className="p-5 w-full bg-neutral-50/50 rounded-[10px] border border-gray-200 flex items-center justify-between gap-5 flex-wrap">
          <div className="space-y-2.5">
            <p className="text-black text-xl font-semibold">Buy Credits</p>
            <p className="text-neutral-600 text-base">
              Track and manage your credits and purchases
            </p>
          </div>
          <Button
            onClick={() => navigate("/usages/buy-credits")}
            rightSection={<SvgIcon icon="PLUS" />}
            className="px-[30px] text-white text-base font-semibold w-44 h-12 py-[13px] rounded-[10px] bg-blue-500"
          >
            Buy Credits
          </Button>
        </div>
        <div className="text-black text-xl font-semibold">Credits History</div>
        <CreditHistoryTable />
      </div>
    </div>
  );
};

export default MyUsages;
