import {
  IChangePasswordRequest,
  IGetForgotPasswordCodeRequest,
  IGoogleSignInRequest,
  IRefreshAccessTokenRequest,
  ISendFeedbackRequest,
  ISendVerificationCodeRequest,
  ISetPasswordRequest,
  ISignInRequest,
  ISignUpRequest,
  ITextExtractionRequest,
  IUpdateProfileRequest,
  IUserDeleteRequest,
  IVerificationCodeRequest,
} from "data/types/request";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import {
  IGenericResponse,
  IImageExtractionResponse,
  ISignInResponse,
  ITextExtractionResponse,
} from "data/types/response";
import { LocalStorageKeys } from "data/constants/storage.constants";
import { API_ENDPOINT } from "./endpoints";
import axios from "./axios";
import { refreshAccessToken } from "./api";

type ApiRequestType =
  | ISignInRequest
  | ISignUpRequest
  | IVerificationCodeRequest
  | ISendVerificationCodeRequest
  | IGetForgotPasswordCodeRequest
  | ISetPasswordRequest
  | IGoogleSignInRequest
  | ITextExtractionRequest
  | IUserDeleteRequest
  | IChangePasswordRequest
  | IUpdateProfileRequest
  | ISendFeedbackRequest
  | string
  | null;

type ApiResponseType =
  | IGenericResponse
  | ISignInResponse
  | ITextExtractionResponse
  | IImageExtractionResponse;

export const commonRequest = async (
  method: ApiMethod,
  endpoint: ApiEndpoint,
  data: ApiRequestType,
  isPrivetApi?: boolean,
): Promise<ApiResponseType> => {
  const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);

  const headers = isPrivetApi
    ? {
        Auth: `${accessToken}`,
        "Content-Type": "application/json", // Assuming JSON data
      }
    : {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Assuming JSON data
      };

  try {
    return await axios({
      method: method,
      url: `${API_ENDPOINT}${endpoint}`,
      data: data,
      headers: headers,
    });
  } catch (error: any) {
    if (error?.message === EXPIRED_TOKEN_ERROR) {
      const tokenData = await getRefreshAccessToken();
      if (tokenData) {
        localStorage.setItem(
          LocalStorageKeys.ACCESS_TOKEN,
          String(tokenData.AccessToken),
        );
      }
    }
    throw error;
  }
};

export const getRefreshAccessToken = () => {
  const refreshToken =
    localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || "";

  const request: IRefreshAccessTokenRequest = {
    refreshtoken: refreshToken,
  };

  try {
    return refreshAccessToken(request);
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
};
