import React, { useEffect, useRef, useState } from "react";
import { Input, Loader } from "@mantine/core";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { Icon, IconOptions } from "components/Icon/Icon";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { ITextExtractionRequest } from "data/types/request";
import { ITextExtractionResponse } from "data/types/response";
import { useAppContext } from "context/App/AppContext";
import { AppActionsEnum } from "context/App/AppContextValues";
import { iconKeys, IIcons } from "data/common";
import useGetUserDetails from "hooks/useGetUserDetails";
import { IAttributes, ICategories, IExtraction } from "data/types/backend";
import ProfileImage from "components/ProfileImage/ProfileImage";
import { notifications } from "@mantine/notifications";
import StepIndicator from "./components/StepIndicator";
import AttributeItem from "./components/AttributeItem";
import UploadFileModal from "./components/UploadFileModal";
import "./Tools.css";
import Feedback from "./components/Feedback";

const setExtractionDataInLocal = (
  data: IExtraction[],
  type: string,
  taskid: string,
  userId: string,
) => {
  const localExtractionData = localStorage.getItem("tools");
  let extractionArray = localExtractionData
    ? JSON.parse(localExtractionData)
    : [];
  const [dataToSave] = data;
  dataToSave.type = type;
  dataToSave.taskid = taskid;
  dataToSave.userId = userId;
  extractionArray = [...extractionArray, dataToSave];
  localStorage.setItem("tools", JSON.stringify(extractionArray));
};

const Tools = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [textList, setTextList] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const { dispatch } = useAppContext();

  const { getUserDetails } = useGetUserDetails();
  const { app } = useAppContext();
  const { userDetails } = app;
  const [filteredData, setFilteredData] = useState<IExtraction[]>([]);
  const [localData, setLocalData] = useState<IExtraction[]>([]);
  const [isDataMounted, setIsDataMounted] = useState<boolean>(false);

  const handleTextExtraction = async () => {
    if (!text) {
      setIsError(true);
      return;
    }
    setLoading(true);
    setIsError(false);
    const request: ITextExtractionRequest = {
      Texts: [text],
    };

    try {
      const data = (await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.TEXT_EXTRACTION,
        request,
        true,
      )) as ITextExtractionResponse;
      if (data) {
        dispatch({
          type: AppActionsEnum.SET_CREDIT,
          payload: {
            credit: data?.credit,
          },
        });
        setTextList([...textList, text]);
        setExtractionDataInLocal(
          data?.results,
          data?.type,
          data?.taskid,
          userDetails?.UUID,
        );
        setText("");
        setLocalData(JSON.parse(localStorage.getItem("tools") || "[]"));
        await getUserDetails();
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleTextExtraction();
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await handleTextExtraction();
    }
  };

  const getCategoriesSteps = (categories: ICategories) => {
    return Object.keys(categories).map((ele: string) => {
      return categories[ele];
    });
  };

  useEffect(() => {
    const localExtractionData: IExtraction[] = JSON.parse(
      localStorage.getItem("tools") || "[]",
    );
    setIsDataMounted(false);
    if (localExtractionData.length <= 0) {
      setIsDataMounted(true);
    }
    setLocalData(localExtractionData);
  }, []);

  useEffect(() => {
    if (userDetails?.UUID) {
      const filteredLocalData: IExtraction[] = localData.filter(
        (data) => data.userId === userDetails.UUID,
      );
      setFilteredData(filteredLocalData);
    }
  }, [localData, userDetails?.UUID]);

  useEffect(() => {
    if (filteredData.length > 0) {
      setIsDataMounted(true);
    }
  }, [filteredData]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isDataMounted, filteredData]);

  const getAttributes = (attributes: IAttributes) => {
    return Object.keys(attributes).map((ele: string, index) => {
      const text = attributes[ele];
      let icon = ele.toUpperCase().replace(/ /g, "_") as keyof IIcons;
      if (iconKeys.includes(icon)) {
        return (
          <AttributeItem key={index} title={ele} icon={icon} text={text} />
        );
      } else {
        return (
          <AttributeItem
            key={index}
            title={ele}
            icon="NPS_BW_LOGO"
            text={text}
          />
        );
      }
    });
  };

  if (!isDataMounted) {
    return (
      <div className="w-full flex justify-center py-10">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative flex p-0 sm:p-4 !pb-0 flex-col h-[calc(100vh-120px)]">
      <div className="pb-24 min-[334px]:pb-[88p]x min-[540px]:pb-[60px] flex flex-col gap-y-5">
        {filteredData?.length > 0 ? (
          filteredData?.map((item: any, i) => {
            return (
              <div key={i} className="w-fit">
                <div
                  className={`flex ${item?.type === "Text" ? "items-center" : "items-start"} gap-x-3`}
                >
                  <ProfileImage userDetails={userDetails} />
                  {item?.type === "Text" ? (
                    <div className="h-10 items-center flex">
                      <p className="text-base font-medium pb-1">
                        {item?.input}
                      </p>
                    </div>
                  ) : (
                    <img
                      src={`data:image;base64,${item?.inputString?.replaceAll('"', "")}`}
                      alt="Uploaded"
                      className="w-36 h-40 rounded-xl object-cover"
                    />
                  )}
                </div>
                <div className="sm:ps-12">
                  <div className="flex gap-6">
                    {item?.type === "Text" ? (
                      <div className="grid">
                        <p className="pt-4 text-xs font-medium pb-2.5">
                          Classification
                        </p>
                        <div className="flex w-fit gap-5 border border-gray-200 pt-3 pr-2 pl-4 rounded-[10px]">
                          <StepIndicator
                            steps={getCategoriesSteps(
                              item?.categories as ICategories,
                            )}
                          />
                          {/* <SvgIcon icon="QUESTION_MARK" /> */}
                        </div>
                        <Feedback
                          OutputKey="Classification"
                          TaskId={item?.taskid}
                          ItemNumber={0}
                        />
                      </div>
                    ) : (
                      <div className="grid">
                        {(item?.categories as ICategories[])?.map(
                          (ele: ICategories, index: number) => (
                            <div key={index}>
                              <p className="pt-4 text-xs font-medium pb-2.5">
                                Classification
                              </p>
                              <div className="flex flex-wrap max-[288px]:justify-center w-fit gap-5 border border-gray-200 pt-3 pr-2 pl-4 rounded-[10px]">
                                <StepIndicator
                                  steps={getCategoriesSteps(ele)}
                                />
                                {/* <SvgIcon icon="QUESTION_MARK" /> */}
                              </div>
                              <Feedback
                                OutputKey="Classification"
                                TaskId={item?.taskid}
                                ItemNumber={index}
                              />
                              <p className="pt-4 text-xs font-medium pb-2.5">
                                Attributes
                              </p>
                              <div className="flex flex-wrap max-[288px]:justify-center gap-y-5 w-fit items-start border border-gray-200 rounded-[10px] p-2.5 gap-x-2.5">
                                {item?.attributes[index]?.map(
                                  (ele: IAttributes) => getAttributes(ele),
                                )}
                              </div>
                              <Feedback
                                OutputKey="Attributes"
                                TaskId={item?.taskid}
                                ItemNumber={index}
                              />
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {item.type === "Text" && (
                  <div className="sm:ps-12">
                    <p className="pt-4 text-xs font-medium pb-2.5">
                      Attributes
                    </p>
                    <div className="w-fit">
                      <div className="flex flex-wrap max-[288px]:justify-center gap-y-5 w-fit items-start border border-gray-200 rounded-[10px] p-2.5 gap-x-2.5">
                        {getAttributes(item?.attributes)}
                      </div>
                      <Feedback
                        OutputKey="Attributes"
                        TaskId={item?.taskid}
                        ItemNumber={0}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="flex h-full items-center justify-center text-lg font-semibold text-gray-500">
            Welcome! Begin your first metadata extraction with Natural Product
            Sense by typing the description or uploading an image below.
          </div>
        )}
      </div>
      <div ref={bottomRef} />
      <div className="sticky bottom-[90px] z-50 min-[371px]:bottom-[76px] min-[571px]:bottom-12 flex h-full w-full flex-col justify-end bg-white">
        <Input.Wrapper
          error={isError ? "Please fill out this field." : ""}
          my="lg"
          classNames={{ error: "px-2" }}
        >
          <Input
            onKeyDown={handleKeyDown}
            component="input"
            rightSectionWidth={100}
            value={text}
            leftSection={
              <Icon
                option={IconOptions.ATTACH}
                size={20}
                className="cursor-pointer"
                color="gray"
                onClick={() => setIsFileUploadModalOpen(true)}
              />
            }
            rightSection={
              <div className="flex items-center">
                {/*    <SvgIcon icon="MIC" className="cursor-pointer" />
                <span className="mx-2 h-4 border-l-2 border-gray-300" /> */}
                {loading ? (
                  <Loader color="gray" size={24} />
                ) : (
                  <SvgIcon
                    icon="SEND"
                    className="cursor-pointer"
                    onClick={handleTextExtraction}
                  />
                )}
              </div>
            }
            onChange={(e) => setText(e.target.value)}
            pointer
            maxLength={100}
            placeholder="Ready to process"
            classNames={{
              section: "pointer-events-auto w-fit px-2",
              input: "bg-gray-100/50 rounded-lg h-[52px] cursor-text",
            }}
          />
        </Input.Wrapper>
      </div>
      <UploadFileModal
        setIsFileUploadModalOpen={setIsFileUploadModalOpen}
        isFileUploadModalOpen={isFileUploadModalOpen}
        setLocalData={setLocalData}
      />
    </div>
  );
};

export default Tools;
