import ExtractImage from "assets/images/ExtractDataImage.png";
import META from "assets/images/supportedNetwork/META.png";
import CHATGPT from "assets/images/supportedNetwork/CHATGPT.png";
import STABILITY from "assets/images/supportedNetwork/STABILITY.png";
import LANGCHAIN from "assets/images/supportedNetwork/LANGCHAIN.png";
import MISTARAL from "assets/images/supportedNetwork/MISTARAL.png";
import TF from "assets/images/supportedNetwork/TF.png";
import TORCH_SCRIPT from "assets/images/supportedNetwork/TORCHSCRIPT.png";
import LEARN from "assets/images/supportedNetwork/LEARN.png";
import GEMINI from "assets/images/supportedNetwork/GEMINI.png";
import Marquee from "react-fast-marquee";

const images = [
  { src: META, alt: "logoIpsum1" },
  { src: CHATGPT, alt: "logoIpsum4" },
  { src: STABILITY, alt: "logoIpsum5" },
  { src: LANGCHAIN, alt: "logoIpsum2" },
  { src: MISTARAL, alt: "logoIpsum3" },
  { src: TF, alt: "logoIpsum4" },
  { src: TORCH_SCRIPT, alt: "logoIpsum5" },
  { src: LEARN, alt: "logoIpsum5" },
  { src: GEMINI, alt: "logoIpsum5" },
];

const ExtractMetaData = () => {
  return (
    <div className="flex flex-col gap-12 max-sm:p-12 p-24">
      <div className="flex w-full flex-col items-center justify-center text-center">
        <div className="sm:text-4xl text-3xl font-bold">
          Our Responsible{" "}
          <span className="italic bg-gradient-to-r from-blue-500 to-orange-400 bg-clip-text text-transparent">
            AI Approach
          </span>
        </div>
        <div className="max-w-[610px] text-center text-zinc-800 text-lg leading-7 pt-2.5 pb-[50px]">
          Responsible AI: We use the best of the AI models as per your
          requirements in terms of cost, privacy and licensing
        </div>
        <Marquee>
          <div className="flex items-center justify-center gap-20">
            {images.map((image, index) => (
              <img key={index} src={image.src} alt={image.alt} />
            ))}
          </div>
        </Marquee>
      </div>
      <div className="flex flex-col items-center gap-12 md:pt-24 pt-10">
        <div className="text-center">
          <div className="sm:text-4xl text-3xl font-bold">
            Turn Text or Image to{" "}
            <span className="bg-gradient-to-r italic from-blue-500 to-orange-400 bg-clip-text text-transparent">
              {" "}
              Actionable Intelligence
            </span>
          </div>
          <div className="mt-2.5 text-base sm:text-lg">
            Effortlessly capture essential data insights from your text or image
            inputs.
          </div>
        </div>
        <img src={ExtractImage} alt="ExtractImage" />
      </div>
    </div>
  );
};

export default ExtractMetaData;
