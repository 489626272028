import SvgIcon from "components/SvgIcon/SvgIcon";

const StepMetaText = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${require("assets/images/metaTextImg.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100%",
      }}
      className="max-lg:p-12 px-24 py-14"
    >
      <div className="flex flex-col items-center gap-3 text-white">
        <div className="text-center text-3xl sm:text-4xl font-bold">
          How to Convert Text or Images to Reveal Key Insights?
        </div>
        <div className="max-w-[610px] text-center text-white text-lg leading-7">
          Utilize cutting-edge AI to effortlessly extract metadata from text and
          images, uncovering valuable insights from your data.
        </div>
      </div>
      <div className="mt-14 flex max-lg:flex-wrap max-lg:justify-center justify-between gap-4">
        <div className="flex w-full items-start gap-3 rounded-xl bg-white p-3 sm:p-5 max-w-[400px]">
          <SvgIcon icon="META_TEXT_ONE" />
          <div>
            <div className="text-xl font-semibold">Step one</div>
            <div className="mt-2 text-base">
              Add your desired text or upload the image from which you wish to
              extract insights
            </div>
          </div>
        </div>
        <div className="flex w-full items-start gap-3 rounded-xl bg-white p-3 sm:p-5 max-w-[400px]">
          <SvgIcon icon="META_TEXT_TWO" />
          <div>
            <div className="text-xl font-semibold">Step Two</div>
            <div className="mt-2 text-base">
              Click on{" "}
              <span className="text-zinc-800 text-base font-medium leading-snug">
                "Generate"
              </span>{" "}
              to let our system extract the information.
            </div>
          </div>
        </div>
        <div className="flex w-full items-start gap-3 rounded-xl bg-white p-3 sm:p-5 max-w-[400px]">
          <SvgIcon icon="META_TEXT_THREE" />
          <div>
            <div className="text-xl font-semibold">Step Three</div>
            <div className="mt-2 text-base">
              The results will be available in the form of classifications and
              attributes.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepMetaText;
