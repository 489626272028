import React from "react";
import DefaultProfilePic from "../DefaultProfilePic/DefaultProfilePic";
import { IUserDetailsResponse } from "data/types/response";
import defaultLogo from "assets/images/default_logo.png";

interface ProfileImageProps {
  userDetails: IUserDetailsResponse;
  className?: string;
}

const getProfileImageSrc = (
  userDetails: IUserDetailsResponse,
): string | null => {
  if (
    userDetails?.["Profile-Image"] &&
    userDetails?.["Profile-Image"].startsWith("http")
  ) {
    return userDetails["Profile-Image"];
  }

  if (userDetails?.["Profile-Image"]) {
    return `data:image;base64,${userDetails["Profile-Image"].replaceAll('"', "")}`;
  }

  if (!userDetails?.Name?.FirstName) {
    return defaultLogo;
  }
  return null;
};

const ProfileImage: React.FC<ProfileImageProps> = ({
  userDetails,
  className,
}) => {
  const profileImageSrc = getProfileImageSrc(userDetails);

  return (
    <div>
      {profileImageSrc ? (
        <img
          src={profileImageSrc}
          alt="Uploaded"
          className={`!w-8 !h-8 rounded-full object-cover ${className}`}
        />
      ) : (
        <DefaultProfilePic
          name={userDetails?.Name?.FirstName}
          className={`!w-8 !h-8 text-xl ${className}`}
        />
      )}
    </div>
  );
};

export default ProfileImage;
