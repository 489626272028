import React from "react";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { IIcons } from "data/common";

interface IAttributeItemProps {
  title: string;
  icon: keyof IIcons;
  text: string;
}

const AttributeItem = ({ title, icon, text }: IAttributeItemProps) => {
  const formattedText = Array.isArray(text) ? text.join(", ") : text;
  return (
    <div className="w-28 break-words">
      <div className="w-28 h-16 bg-blue-500/10 p-2 rounded-md text-center relative grid justify-center gap-y-1">
        <p className="text-[10px] capitalize font-semibold">{title}</p>
        <SvgIcon icon={icon} className="mx-auto" />
        {/*
        <SvgIcon icon="QUESTION_MARK" className="absolute top-1 right-1" />
*/}
      </div>
      <p className="text-center text-gray-500 text-xs pt-1.5">
        {formattedText}
      </p>
    </div>
  );
};

export default AttributeItem;
