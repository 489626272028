import { Dispatch, SetStateAction } from "react";

export interface ProfileDetail {
  "First Name": string;
  "Last Name": string;
  Email: string;
  Phone: string;
}

export interface ISocialMediaModalProps {
  isSocialMediaModal: boolean;
  setIsSocialMediaModal: Dispatch<SetStateAction<boolean>>;
  socialMediaData: any;
  setSocialMediaData: Dispatch<SetStateAction<any>>;
}

export interface IProfileUpdateModalProps {
  isProfileModalOpen: boolean;
  setIsProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  setProfileData: Dispatch<SetStateAction<ProfileDetail[]>>;
  profileData: ProfileDetail[];
}

export interface IMyUsagesModalProps {
  isAvailableCreditModalOpen: boolean;
  setIsAvailableCreditModalOpen: Dispatch<SetStateAction<boolean>>;
}

export enum DateRange {
  TWENTY_FOUR_HOURS = "24hr",
  SEVEN_DAYS = "7d",
  THIRTY_DAYS = "30d",
}

export const DateRangeOptions = [
  DateRange.TWENTY_FOUR_HOURS,
  DateRange.SEVEN_DAYS,
  DateRange.THIRTY_DAYS,
];
