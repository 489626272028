import { Button, Modal } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface IRequestSuccessModalProps {
  isRequestModalOpen: boolean;
  setIsRequestModalOpen: Dispatch<SetStateAction<boolean>>;
}

const RequestSuccessModal = ({
  isRequestModalOpen,
  setIsRequestModalOpen,
}: IRequestSuccessModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      classNames={{
        title: "text-lg font-medium",
        body: "p-5",
        content: "rounded-xl",
      }}
      withCloseButton={false}
      size={356}
      centered
      opened={isRequestModalOpen}
      onClose={() => setIsRequestModalOpen(false)}
    >
      <div className="space-y-5">
        <div className="text-black text-xl font-semibold">
          We’ve Got Your Request
        </div>

        <div className="text-zinc-800 text-sm">
          Your request has been successfully submitted. I will reach out to you
          shortly. Your credits will update your profile or add your profile.
        </div>

        <Button
          onClick={() => navigate("/tools")}
          fullWidth
          className="h-[42px] px-[30px] py-[11px] bg-blue-500 rounded-[10px] text-white text-sm font-semibold"
        >
          Back to Tool
        </Button>
      </div>
    </Modal>
  );
};

export default RequestSuccessModal;
