import React from "react";
import { Button, Tabs } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import "./Settings.css";
import PasswordTab from "./components/PasswordTab";
import { LocalStorageKeys } from "../../data/constants/storage.constants";

const Settings = () => {
  const navigate = useNavigate();

  return (
    <div className="pb-24 min-[334px]:pb-[88px] min-[540px]:pb-[60px]">
      <div className="p-4">
        <div className="rounded-[16px] border border-gray-200 p-4 space-y-[30px]">
          <div className="text-xl font-semibold">Setting</div>
          <div>
            <Tabs
              variant="unstyled"
              defaultValue="payment"
              className="flex flex-col gap-5"
            >
              <Tabs.List grow className="main-tab">
                <Tabs.Tab className="custom-tab" value="payment">
                  Payment
                </Tabs.Tab>
                {localStorage.getItem(LocalStorageKeys.ID_PROVIDER) !==
                  "Google" && (
                  <Tabs.Tab value="password" className="custom-tab">
                    Password
                  </Tabs.Tab>
                )}
              </Tabs.List>
              <Tabs.Panel value="payment">
                <Button
                  className="rounded-lg"
                  onClick={() => navigate("/usages/buy-credits")}
                >
                  Buy Credit
                </Button>
              </Tabs.Panel>
              <Tabs.Panel value="password">
                <PasswordTab />
              </Tabs.Panel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
