import { Divider } from "@mantine/core";
import npsLogo from "assets/images/npsFullLogo.svg";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="px-10 pt-10 pb-5 md:px-24 md:pt-20">
      <div className="flex flex-wrap justify-between gap-x-20 gap-y-7 pb-12">
        <div>
          <div className="mb-5 text-xl font-medium">Offerings</div>
          <div className="flex flex-col gap-3 text-base">
            <Link
              to="/login"
              className="text-zinc-800 text-base hover:text-blue-500 transition-all duration-150"
            >
              Image To Meta
            </Link>
            <Link
              to="/login"
              className="text-zinc-800 text-base hover:text-blue-500 transition-all duration-150"
            >
              Text To Meta
            </Link>
          </div>
        </div>
        <div>
          <div className="mb-5 text-xl font-medium">Quick Links</div>
          <div className="flex flex-col gap-3 text-base">
            <a
              className="text-zinc-800 text-base hover:text-blue-500 transition-all duration-150"
              href="#features"
            >
              Features
            </a>
            <a
              className="text-zinc-800 text-base hover:text-blue-500 transition-all duration-150"
              href="#faq"
            >
              FAQs
            </a>
          </div>
        </div>
        <div>
          <div className="mb-5 text-xl font-medium">Legals</div>
          <div className="flex flex-col gap-3 text-base">
            <Link
              to="/privacy-policy"
              className="text-zinc-800 text-base hover:text-blue-500 transition-all duration-150"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
        <div>
          <div className="mb-5 text-xl font-medium">Reach us</div>
          <div className="flex flex-col gap-3 text-base">
            <div className="flex items-center gap-2">
              <SvgIcon icon="SMS" />
              <a href="mailto:contact@mohap.ai">contact@mohap.ai</a>
            </div>
            {/*  <div className="flex items-center gap-2">
              <SvgIcon icon="CALL" />
              <a href="tel:000111222">+91 000111222</a>
            </div> */}
            {/* <div className="flex items-center gap-2">
              <SvgIcon icon="LOCATION_BLUE" />
              <div>Lorem ipsum dolor sit amet.</div>
            </div> */}
          </div>
        </div>
      </div>
      <Divider size="sm" my="md" />
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex items-end gap-1">
          <img src={npsLogo} alt="logo" width={150} />
          <div className="text-[7.55px] uppercase pb-1">beta</div>
        </div>
        <div>&#169; 2024 NPS. All rights reserved.</div>
        <div className="flex gap-3">
          <div className="cursor-pointer rounded-full border-2 border-solid border-gray-200 p-1">
            <SvgIcon icon="INSTAGRAM" />
          </div>
          <div className="cursor-pointer rounded-full border-2 border-solid border-gray-200 p-1">
            <SvgIcon icon="FACEBOOK" />
          </div>
          <div className="cursor-pointer rounded-full border-2 border-solid border-gray-200 p-1">
            <SvgIcon icon="TWITTER" />
          </div>
          <div className="cursor-pointer rounded-full border-2 border-solid border-gray-200 p-1">
            <SvgIcon icon="YOUTUBE" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
