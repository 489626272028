import { Divider } from "@mantine/core";
import HomeLayout from "components/HomeLayout/HomeLayout";
import NPSWelcome from "./components/NPSWelcome";
import ExtractMetaData from "./components/ExtractMetaData";
import StepMetaText from "./components/StepMetaText";
import MetaGenerator from "./components/MetaGenerator";
import ImageMetaGenerator from "./components/ImageMetaGenerator";
import FrequentlyAskedQuestions from "./components/FrequentlyAskedQuestions";
import ExploreMoreTools from "./components/ExploreMoreTools";

const Home = () => {
  return (
    <HomeLayout>
      <>
        <NPSWelcome />
        <Divider className="max-md:mx-5 mx-24" />
        <ExtractMetaData />
        <StepMetaText />
        <MetaGenerator />
        <ImageMetaGenerator />
        <FrequentlyAskedQuestions />
        <ExploreMoreTools />
      </>
    </HomeLayout>
  );
};
export default Home;
