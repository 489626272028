import axios from "axios";
import { API_ENDPOINT } from "./endpoints";

const baseURL = API_ENDPOINT;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  return config;
});

instance.interceptors.response.use(
  (response) => (response && response.data) || response,
  (error) => {
    return Promise.reject(
      (error && error.response && error.response.data) || error,
    );
  },
);

export default instance;
