import React from "react";
import { Modal } from "@mantine/core";
import SuccessGif from "assets/success.gif";

interface ISuccessModalProps {
  isSuccessModalOpen: boolean;
  successMessage: string;
  onCloseFunction: () => void;
}

const SuccessModal = ({
  isSuccessModalOpen,
  successMessage,
  onCloseFunction,
}: ISuccessModalProps) => {
  return (
    <Modal
      classNames={{
        title: "text-lg font-medium",
        close: "font-medium text-black",
        body: "p-5 pt-0 px-12",
        content: "rounded-xl",
      }}
      size={258}
      centered
      opened={isSuccessModalOpen}
      onClose={onCloseFunction}
    >
      <div className="text-center flex flex-col items-center">
        <img src={SuccessGif} alt="Success" width={60} height={60} />
        <p className="text-lg font-medium py-2">Successfully</p>
        <p className="text-sm">{successMessage}</p>
      </div>
    </Modal>
  );
};

export default SuccessModal;
