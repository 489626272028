import React, { useState } from "react";
import {
  Button,
  FileInput,
  Modal,
  TextInput,
  InputWrapper,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IUpdateProfileRequest } from "data/types/request";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { notifications } from "@mantine/notifications";
import useGetUserDetails from "hooks/useGetUserDetails";
import { encodeImageToBase64 } from "pages/Tools/components/UploadFileModal";
import { IProfileUpdateModalProps } from "../Profile.type";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

const ProfileUpdateModal = ({
  setIsProfileModalOpen,
  isProfileModalOpen,
  setProfileData,
  profileData,
}: IProfileUpdateModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const initialDataObject = profileData?.[0];
  const { getUserDetails } = useGetUserDetails();
  const initialValues = {
    firstName: initialDataObject["First Name"],
    lastName: initialDataObject["Last Name"],
    email: initialDataObject?.Email,
    phone: initialDataObject?.Phone,
  };
  const [countryCode, setCountryCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();

  const form = useForm({
    initialValues: initialValues,
    validate: {
      firstName: (value) => (value === "" ? "First name is required" : null),
      lastName: (value) => (value === "" ? "Last name is required" : null),
      phone: () => {
        try {
          const number = phoneUtil.parseAndKeepRawInput(
            phoneNumber,
            countryCode,
          );
          return phoneUtil.isValidNumberForRegion(number, countryCode)
            ? null
            : "Invalid phone number";
        } catch (e) {
          return "Invalid phone number";
        }
      },
    },
  });

  const handlePhoneChange = (value: string, country: CountryData) => {
    const dialCodeLength = country?.dialCode?.length;
    setCountryCode(country?.countryCode?.toUpperCase());
    const phoneWithoutCountryCode = value.slice(dialCodeLength);
    setPhoneNumber(phoneWithoutCountryCode);
    form.setFieldValue("phone", value);
  };

  const submitProfileDetails = async (value: (typeof form)["values"]) => {
    const base64String = await encodeImageToBase64(file as File);
    setLoading(true);
    setProfileData([
      {
        "First Name": value?.firstName,
        "Last Name": value?.lastName,
        Email: value?.email,
        Phone: value?.phone,
      },
    ]);
    const payload: IUpdateProfileRequest = {
      firstname: value?.firstName,
      lastname: value?.lastName,
      email: value?.email,
      phone: value?.phone,
      profilepic: base64String as string,
    };
    try {
      await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.UPDATE_PROFILE,
        payload,
        true,
      );
      notifications.show({
        color: "green",
        message: "Profile updated successfully.",
        autoClose: 5000,
      });
      await getUserDetails();
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await submitProfileDetails(value);
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
    setIsProfileModalOpen(false);
  };

  return (
    <>
      <Modal
        opened={isProfileModalOpen}
        onClose={() => {
          form.reset();
          setIsProfileModalOpen(false);
        }}
        title="My Profile"
        centered
        classNames={{
          title: "text-lg font-semibold",
          close: "font-medium text-black",
          body: "p-5 pt-0",
          content: "rounded-xl",
          header: "p-5 pb-0",
        }}
        radius={16}
        size={674}
      >
        <div className="w-full pt-5">
          <form
            className="flex w-full flex-col gap-5"
            onSubmit={form.onSubmit(submitProfileDetails)}
          >
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-5">
              <TextInput
                radius="md"
                label="First Name"
                placeholder="Enter your first name"
                key={form.key("firstName")}
                {...form.getInputProps("firstName")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[43px] border shadow border-gray-200 px-4 py-[11px]",
                }}
              />
              <TextInput
                radius="md"
                label="Last Name"
                placeholder="Enter your last name"
                key={form.key("lastName")}
                {...form.getInputProps("lastName")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[43px] border shadow border-gray-200 px-4 py-[11px]",
                }}
              />
              <TextInput
                radius="md"
                disabled
                label="Email"
                placeholder="Enter your email"
                key={form.key("email")}
                {...form.getInputProps("email")}
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[43px] border shadow border-gray-200 px-4 py-[11px]",
                }}
              />
              <InputWrapper
                classNames={{ error: "py-2" }}
                label=""
                error={form.errors.phone}
              >
                <p className="font-semibold text-sm py-2">Phone</p>
                <PhoneInput
                  onChange={handlePhoneChange}
                  onMount={handlePhoneChange}
                  value={form.getValues().phone}
                  country="auto"
                  placeholder="Enter your phone"
                />
              </InputWrapper>

              <FileInput
                classNames={{
                  label: "mb-3 text-sm font-medium",
                  input:
                    "bg-whisper-gray text-gray-500 h-[43px] border shadow border-gray-200 px-4 py-[11px]",
                }}
                label="Profile Pic"
                placeholder="Upload profile pic"
                onChange={(file) => setFile(file as File)}
              />
            </div>

            <div className="flex flex-wrap-reverse justify-end gap-[14px]">
              <Button
                onClick={() => {
                  form.reset();
                  setIsProfileModalOpen(false);
                }}
                className="p-0 font-bold border border-gray-300 max-[346px]:w-full"
                classNames={{
                  root: "rounded-[10px] h-10",
                  label: "py-[11px] px-[30px]",
                }}
                variant="outline"
                color="gray"
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                type="submit"
                size="sm"
                radius="md"
                className="h-[42px] font-bold p-0 max-[346px]:w-full"
                classNames={{ label: "py-[11px] px-[30px]" }}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ProfileUpdateModal;
