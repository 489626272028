import {
  AppShell,
  Group,
  Button,
  UnstyledButton,
  Burger,
  Divider,
  Menu,
  Text,
  RingProgress,
  Center,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import npsLogo from "assets/images/npsFullLogo.svg";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { useAuthContext } from "context/Auth/AuthContext";
import React, { useEffect, useState } from "react";
import SignOutConfirmationModal from "components/ConfirmationModals/SignOutConfirmationModal";
import ProfileImage from "components/ProfileImage/ProfileImage";
import { useAppContext } from "context/App/AppContext";
import Footer from "pages/Home/components/Footer";

export interface ILayoutProps {
  children?: JSX.Element;
}

const HomeLayout = ({ children }: ILayoutProps) => {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();
  const { auth } = useAuthContext();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);
  const { app } = useAppContext();
  const { userDetails } = app;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const creditRange = (userDetails?.Credits * 100) / userDetails?.TotalCredits;

  return (
    <AppShell
      header={{ height: 72 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { desktop: true, mobile: !opened },
      }}
    >
      <SignOutConfirmationModal
        setIsSignOutModalOpen={setIsSignOutModalOpen}
        isSignOutModalOpen={isSignOutModalOpen}
      />
      <AppShell.Header>
        <Group h="100%" justify="space-between" className="max-md:px-5 px-24">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <div
            className="flex items-end gap-1 max-sm:hidden cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={npsLogo} alt="logo" width={150} />
            <div className="text-[7.55px] uppercase pb-1">beta</div>
          </div>
          <Group justify="end" style={{ flex: 1 }}>
            <Group gap={20} className="text-base font-medium">
              {auth?.isLoggedIn ? (
                <div className="flex w-full justify-end items-center">
                  <RingProgress
                    size={40}
                    thickness={4}
                    sections={[{ value: creditRange, color: "blue" }]}
                    label={
                      <Center className="text-xs font-medium">
                        {userDetails?.Credits}
                      </Center>
                    }
                  />
                  <Divider
                    orientation="vertical"
                    classNames={{
                      root: "w-5 my-5 rotate-90 border border-stone-300",
                    }}
                  />
                  <Menu
                    opened={isProfileOpen}
                    onChange={() => {
                      setIsProfileOpen(!isProfileOpen);
                    }}
                    classNames={{
                      dropdown:
                        "p-0 rounded-xl shadow shadow-gray-500/20 !w-56",
                      item: "px-1.5 py-2.5",
                    }}
                  >
                    <Menu.Target>
                      <UnstyledButton>
                        <Group classNames={{ root: "gap-x-1 md:pr-3" }}>
                          <ProfileImage userDetails={userDetails} />
                          <div style={{ flex: 1 }}>
                            <Text
                              classNames={{ root: "ps-2.5" }}
                              className="text-sm max-[400px]:hidden"
                              fw={500}
                            >
                              {userDetails?.Name?.FirstName}{" "}
                              {userDetails?.Name?.LastName}
                            </Text>
                          </div>
                          {isProfileOpen ? (
                            <SvgIcon icon="UP_ARROW" />
                          ) : (
                            <SvgIcon icon="DOWN_ARROW" />
                          )}
                        </Group>
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        leftSection={<ProfileImage userDetails={userDetails} />}
                      >
                        <p>
                          {userDetails?.Name?.FirstName}{" "}
                          {userDetails?.Name?.LastName}
                        </p>
                        <p className="text-xs text-blue-500">
                          {userDetails?.Email}
                        </p>
                      </Menu.Item>
                      <Menu.Divider />
                      <div className="px-3">
                        <Menu.Item
                          onClick={() => navigate("/profile")}
                          leftSection={
                            <SvgIcon icon="PROFILE" className="w-6 h-6" />
                          }
                        >
                          My Profile
                        </Menu.Item>
                        <Menu.Item
                          classNames={{ item: "pl-2.5" }}
                          onClick={() => setIsSignOutModalOpen(true)}
                          leftSection={
                            <SvgIcon icon="SIGN_OUT" className="w-5 h-5" />
                          }
                        >
                          Sign Out
                        </Menu.Item>
                      </div>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              ) : (
                <>
                  <UnstyledButton>
                    <div className="flex cursor-pointer max-sm:hidden items-center gap-2">
                      <SvgIcon icon="COMMUNITY" />
                      <div>Join our community</div>
                    </div>
                  </UnstyledButton>
                  <Button
                    variant="filled"
                    size="md"
                    radius="md"
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </Button>
                </>
              )}
            </Group>
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar
        maw={400}
        py="md"
        px={20}
        className="flex flex-col gap-4"
      >
        {auth.isLoggedIn ? (
          <>
            <NavLink className="p-2 text-center rounded-xl" to="/home">
              Home
            </NavLink>
            <NavLink className="p-2 text-center rounded-xl" to="/tools">
              Tools
            </NavLink>
            <NavLink className="p-2 text-center rounded-xl" to="/profile">
              Profile
            </NavLink>
          </>
        ) : (
          <>
            <UnstyledButton className="flex cursor-pointer items-center gap-2">
              <SvgIcon icon="COMMUNITY" />
              <div className="text-base font-medium">Join our community</div>
            </UnstyledButton>
            <div className="flex h-full w-full items-end">
              <Button
                variant="filled"
                size="md"
                radius="md"
                className="w-full"
                onClick={() => navigate("/login")}
              >
                Sign In
              </Button>
            </div>
          </>
        )}
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
      <Footer />
    </AppShell>
  );
};
export default HomeLayout;
