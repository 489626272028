import { Button, Modal } from "@mantine/core";
import React, { Dispatch, SetStateAction, useState } from "react";
import SuccessModal from "./SuccessModal";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { AuthActionsEnum } from "context/Auth/AuthContextValues";
import { useAuthContext } from "context/Auth/AuthContext";
import { notifications } from "@mantine/notifications";
import { LocalStorageKeys } from "data/constants/storage.constants";

interface IDeleteConfirmationModalProps {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
}
const DeleteConfirmationModal = ({
  setIsDeleteModalOpen,
  isDeleteModalOpen,
}: IDeleteConfirmationModalProps) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { dispatch } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleUserDelete = async () => {
    const userToken = localStorage.getItem("accessToken");
    if (!userToken) {
      notifications.show({
        color: "red",
        message: "Token is not available",
        autoClose: 5000,
      });
      return;
    }
    setIsLoading(true);
    try {
      await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.DELETE_USER,
        { accesstoken: userToken },
        true,
      );
      setIsDeleteModalOpen(false);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
        setIsSuccessModalOpen(false);
        dispatch({
          type: AuthActionsEnum.SET_IS_LOGGED_IN,
          payload: {
            isLoggedIn: false,
          },
        });
      }, 1000);
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleUserDelete();
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        onCloseFunction={() => setIsSuccessModalOpen(false)}
        successMessage="Your account has been successfully deleted."
      />
      <Modal
        classNames={{
          title: "text-lg font-medium",
          close: "font-medium text-black",
          body: "p-5 pt-0",
          content: "rounded-xl",
          header: "p-5 pb-2.5",
        }}
        withCloseButton={false}
        size={340}
        centered
        opened={isDeleteModalOpen}
        title="Delete Account"
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <p>
          Once an account is deleted, the same email cannot be used to create a
          new account. Do you wish to continue?
        </p>
        <div className="flex gap-3.5 pt-5 max-[360px]:flex-wrap-reverse">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            classNames={{ root: "rounded-[10px] w-full h-10" }}
            variant="outline"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={handleUserDelete}
            classNames={{ root: "rounded-[10px] w-full h-10 bg-blue-500/90" }}
          >
            Delete Account
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default DeleteConfirmationModal;
