import { ICONS } from "./Icons";
import { ISvgIconProps } from "data/common";

const SvgIcon = ({
  fill,
  icon,
  key,
  ...restProps
}: ISvgIconProps): JSX.Element => (
  <i key={key} {...restProps}>
    {ICONS[`${icon}`]}
  </i>
);

export default SvgIcon;
