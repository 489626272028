import { Dispatch, SetStateAction, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Modal, Button } from "@mantine/core";
import SvgIcon from "components/SvgIcon/SvgIcon";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import { AppActionsEnum } from "context/App/AppContextValues";
import { useAppContext } from "context/App/AppContext";
import { IAttributes, ICategories, IExtraction } from "data/types/backend";
import useGetUserDetails from "hooks/useGetUserDetails";
import { notifications } from "@mantine/notifications";

interface IUploadFileModalProps {
  setIsFileUploadModalOpen: (isOpen: boolean) => void;
  isFileUploadModalOpen: boolean;
  setLocalData: Dispatch<SetStateAction<IExtraction[]>>;
}

const setExtractionDataInLocal = (
  data: IExtraction[],
  type: string,
  taskid: string,
  userId: string,
  input?: string,
) => {
  const localExtractionData = localStorage.getItem("tools");
  let extractionArray = localExtractionData
    ? JSON.parse(localExtractionData)
    : [];

  const mergedCategories: ICategories[] = [];
  const mergedAttributes: IAttributes[][] = [];

  data.forEach((ele: IExtraction) => {
    mergedCategories.push(ele?.categories as ICategories);
    mergedAttributes.push([ele?.attributes] as IAttributes[]);
  });
  const dataToSave = {
    type,
    taskid,
    userId,
    inputString: input,
    attributes: mergedAttributes,
    categories: mergedCategories,
  };
  extractionArray = [...extractionArray, dataToSave];
  localStorage.setItem("tools", JSON.stringify(extractionArray));
};

export const encodeImageToBase64 = (file: File) => {
  if (!file) return;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const base64String = result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

const UploadFileModal = ({
  setIsFileUploadModalOpen,
  isFileUploadModalOpen,
  setLocalData,
}: IUploadFileModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>();
  const { app } = useAppContext();
  const { dispatch } = useAppContext();
  const fileTypes = ["JPG", "PNG", "WEBP", "JPEG"];
  const { getUserDetails } = useGetUserDetails();
  const { userDetails } = app;

  const handleDeleteImage = (file: File) => {
    const allFiles = [...files];
    const filteredFiles = allFiles.filter(
      (data: File) => data?.name !== file?.name,
    );
    setFiles(filteredFiles);
  };

  const handleFileUploader = (file: File) => {
    if (!files.length) {
      setFiles([...files, file]);
      setError("");
    } else {
      setError("You can only add one file.");
    }
  };

  const handleImageExtraction = async () => {
    if (!files.length) return;
    setLoading(true);
    setError("");

    const base64String = await encodeImageToBase64(files[0]);

    try {
      const data = (await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.IMAGE_EXTRACTION,
        base64String as string,
        true,
      )) as any;
      if (data) {
        dispatch({
          type: AppActionsEnum.SET_CREDIT,
          payload: {
            credit: data?.credit,
          },
        });
        setExtractionDataInLocal(
          data?.results[0]?.output,
          data?.type,
          data?.taskid,
          userDetails?.UUID,
          data?.results[0]?.input,
        );
        setLocalData(JSON.parse(localStorage.getItem("tools") || "[]"));
        await getUserDetails();
      }
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handleImageExtraction();
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
      setIsFileUploadModalOpen(false);
      setFiles([]);
    }
  };

  return (
    <Modal
      classNames={{
        title: "text-lg font-medium",
        header: "pb-0",
        close: "text-black",
      }}
      size={500}
      radius={16}
      opened={isFileUploadModalOpen}
      onClose={() => setIsFileUploadModalOpen(false)}
      title="Upload File"
      centered
    >
      <FileUploader
        onSizeError={(error: string) => setError(error)}
        maxSize={3}
        handleChange={handleFileUploader}
        name="file"
        types={fileTypes}
        label=""
        disable={!!error}
      >
        <div className="mt-5 rounded-xl border-2 border-dashed p-4 text-center space-y-2.5 sm:px-[84px] sm:py-10">
          <SvgIcon icon="UPLOAD" className="flex justify-center" />
          <p className="text-lg font-medium">Drag and Drop an file here</p>
          <p className="text-gray-500 text-[10px]">
            PNG, JPG, JPEG or WEBP up to Maximum size: 3MB
          </p>
          <p className="text-sm font-medium">or</p>
          <Button className="border w-36 h-10 border-gray-200 !bg-transparent px-7 font-semibold !text-gray-500 py-2.5 rounded-[10px]">
            Upload a File
          </Button>
        </div>
      </FileUploader>
      {error && <div className="ml-2 mt-1 text-sm text-red-500">{error}</div>}
      <div className="my-5 space-y-3">
        {files?.length > 0 &&
          files.map((file: File, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between rounded-xl bg-blue-500/10 px-5 py-3.5"
            >
              <p className="break-all">{file?.name}</p>
              <button
                disabled={loading}
                onClick={() => handleDeleteImage(file)}
              >
                <SvgIcon
                  icon="DELETE"
                  className={loading ? "cursor-not-allowed" : "cursor-pointer"}
                />
              </button>
            </div>
          ))}
      </div>
      <div className="flex items-center justify-end gap-x-3.5">
        <Button
          className="border !bg-transparent border-gray-200 px-7 font-semibold h-11 !text-gray-400 py-2.5 rounded-[10px]"
          onClick={() => {
            setError("");
            setIsFileUploadModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          className="h-11 border border-gray-200 bg-blue-500 px-7 font-semibold text-white py-2.5 rounded-[10px]"
          onClick={handleImageExtraction}
        >
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
