import { Modal, RingProgress, Text } from "@mantine/core";
import React from "react";
import { IMyUsagesModalProps } from "../Profile.type";

const AvailableCreditModal = ({
  isAvailableCreditModalOpen,
  setIsAvailableCreditModalOpen,
}: IMyUsagesModalProps) => {
  return (
    <Modal
      classNames={{
        body: "p-5",
      }}
      opened={isAvailableCreditModalOpen}
      centered
      withCloseButton={false}
      size={306}
      radius={16}
      onClose={() => setIsAvailableCreditModalOpen(false)}
    >
      <div className="flex flex-wrap gap-5 items-center">
        <div className="text-sm	font-medium	">Available Credits</div>
        <div>
          <RingProgress
            size={140}
            thickness={16}
            sections={[{ value: 80, color: "blue" }]}
            label={
              <>
                <Text fw={700} ta="center" className="text-base">
                  10
                </Text>
                <Text
                  ta="center"
                  className="opacity-70 text-center text-black text-xs"
                >
                  Available Credits
                </Text>
              </>
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default AvailableCreditModal;
