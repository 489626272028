import React from "react";

const DefaultProfilePic = ({
  name,
  className,
}: {
  name: string | undefined;
  className?: string;
}) => {
  const getInitial = (name: string | undefined) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <div
      className={`w-[50px] h-[50px] rounded-full bg-orange-500 text-white flex items-center justify-center text-4xl uppercase ${className}`}
    >
          {getInitial(name)}
    </div>
  );
};

export default DefaultProfilePic;
