import StepIndicator from "pages/Tools/components/StepIndicator";
import AttributeItem from "pages/Tools/components/AttributeItem";
import MetaGeneratorImage from "assets/images/MetaGenerator.png";

const MetaGenerator = () => {
  return (
    <div className="max-lg:p-10 p-24">
      <div className="flex flex-col items-center">
        <div className="text-center text-3xl sm:text-4xl font-bold">
          Examples of AI-Driven{" "}
          <span className="bg-gradient-to-r from-blue-500 to-orange-400 bg-clip-text text-transparent italic">
            Image Analysis
          </span>
        </div>
        <div className="mt-2.5 text-center text-base sm:text-lg max-w-[500px]">
          Utilize our AI to extract and generate discover actionable insights
          from your photos and images
        </div>
      </div>
      <div className="mt-14 flex flex-col gap-7">
        <div className="flex max-lg:flex-col rounded-2xl bg-stone-100">
          <div className="flex w-2/5 max-lg:w-full flex-col gap-5 p-7">
            <div className="sm:text-4xl text-3xl font-semibold">
              Explore Your Images
            </div>
            <div className="text-base sm:text-lg">
              Effortlessly extract essential insights from your content with
              NPS, gaining enhanced control over your data.
            </div>
            <div className="flex flex-col gap-5 rounded-2xl bg-white p-5">
              <div>
                <div className="text-zinc-800 text-lg font-semibold">
                  Categories
                </div>
                <div className="mt-2.5 text-sm leading-[21px]">
                  <div>{`{'industry':'Fashion',`}</div>
                  <div>'category':'Clothing',</div>
                  <div>{`'sub-category':'Dresses'}`}</div>
                </div>
              </div>
              <div>
                <div className="text-zinc-800 text-lg font-semibold">
                  Attributes
                </div>
                <div className="mt-2.5 text-sm break-words leading-[21px]">
                  <div>{`{'product':'dress',`}</div>
                  <div>'color':'white',</div>
                  <div>'sleeve':'sleeveless',</div>
                  <div>'material':'lace',</div>
                  <div>{`'accessories':'round woven straw crossbody bag'}`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-3/5 max-lg:w-full items-end justify-end pt-7 pl-7">
            <img src={MetaGeneratorImage} alt="MetaGeneratorImage" />
          </div>
        </div>
        <div className="grid max-md:grid-cols-1 grid-cols-2 gap-7">
          <div className="flex flex-col gap-5 rounded-2xl bg-stone-100 p-7">
            <div className="text-3xl sm:text-4xl font-semibold">
              Classification
            </div>
            <div className="text-base sm:text-lg">
              Effortlessly extract valuable metadata from your content with
              classification.
            </div>
            <div className="flex flex-row max-[470px]:flex-wrap gap-3 justify-between">
              <StepIndicator steps={["Fashion", "Clothing", "Dresses"]} />
            </div>
          </div>
          <div className="flex flex-col gap-5 rounded-2xl bg-stone-100 p-7 overflow-x-hidden">
            <div className="text-3xl sm:text-4xl font-semibold">Attributes</div>
            <div className="text-base sm:text-lg">
              Effortlessly extract valuable metadata from your content with
              Attributes.
            </div>
            <div className="mt-3 flex overflow-x-auto justify-between max-xl:justify-around gap-x-5 gap-y-7 border border-gray-200 p-4  rounded-xl">
              <AttributeItem icon="PRODUCT" title="Product" text="Dress" />
              <AttributeItem icon="COLOR" title="Color" text="White" />
              <AttributeItem icon="SLEEVE" title="Sleeve" text="Sleeveless" />
              <AttributeItem icon="MATERIAL" title="Material" text="Lace" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaGenerator;
