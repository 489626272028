import React, { createContext, ReactNode, useContext, useReducer } from "react";
import {
  AuthActions,
  AuthActionsEnum,
  AuthInitialState,
  IAuthContext,
} from "./AuthContextValues";

export const AuthReducer = (
  state: IAuthContext,
  action: AuthActions,
): IAuthContext => {
  const { type, payload } = action;

  if (type === AuthActionsEnum.SET_IS_LOGGED_IN) {
    return {
      ...state,
      isLoggedIn: payload.isLoggedIn,
    };
  }

  return state;
};

interface AuthContextProps {
  auth: IAuthContext;
  dispatch: React.Dispatch<AuthActions>;
}

export const AuthContext = createContext<AuthContextProps>({
  auth: AuthInitialState,
  dispatch: () => {},
});

export const AuthContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [auth, dispatch] = useReducer(AuthReducer, AuthInitialState);
  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);
