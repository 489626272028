import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IChangePasswordRequest } from "data/types/request";
import { commonRequest } from "services/api/commonRequest";
import {
  ApiEndpoint,
  ApiMethod,
  EXPIRED_TOKEN_ERROR,
} from "data/constants/api.constants";
import SuccessModal from "components/ConfirmationModals/SuccessModal";
import { AuthActionsEnum } from "context/Auth/AuthContextValues";
import { useAuthContext } from "context/Auth/AuthContext";
import { notifications } from "@mantine/notifications";
import { LocalStorageKeys } from "data/constants/storage.constants";

interface IPasswordChangeModalProps {
  isPwdModalOpen: boolean;
  setIsPwdModalOpen: Dispatch<SetStateAction<boolean>>;
}

const PasswordChangeModal = ({
  isPwdModalOpen,
  setIsPwdModalOpen,
}: IPasswordChangeModalProps) => {
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { dispatch } = useAuthContext();

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    validate: {
      currentPassword: (value) =>
        value.trim() === "" ? "Current password is required" : null,
      newPassword: (value) => {
        if (value.length < 8) {
          return "Password must have at least 8 characters";
        }
        if (!/[A-Z]/.test(value)) {
          return "Password must have at least one uppercase letter";
        }
        if (!/[a-z]/.test(value)) {
          return "Password must have at least one lowercase letter";
        }
        if (!/[0-9]/.test(value)) {
          return "Password must have at least one number";
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          return "Password must have at least one special character";
        }
        return null;
      },
      confirmPassword: (value, values) =>
        value !== values.newPassword ? "Passwords did not match" : null,
    },
  });

  const handlePasswordChange = async (value: (typeof form)["values"]) => {
    setLoading(true);
    const payload: IChangePasswordRequest = {
      "previous-password": value.currentPassword,
      "new-password": value.newPassword,
    };
    try {
      const response = await commonRequest(
        ApiMethod.POST,
        ApiEndpoint.CHANGE_PASSWORD,
        payload,
        true,
      );
      if ("message" in response && response.message) {
        setMessage(response.message);
      }
      setIsSuccessModalOpen(true);
      setIsPwdModalOpen(false);
      form.reset();
      setTimeout(() => {
        localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
        dispatch({
          type: AuthActionsEnum.SET_IS_LOGGED_IN,
          payload: {
            isLoggedIn: false,
          },
        });
      }, 1000);
    } catch (err: any) {
      if (err?.message === EXPIRED_TOKEN_ERROR) {
        await handlePasswordChange(value);
        return;
      }
      notifications.show({
        color: "red",
        message: err?.Error || err?.message,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        onCloseFunction={() => {
          setIsSuccessModalOpen(false);
        }}
        successMessage={message}
      />
      <Modal
        classNames={{
          title: "text-lg font-medium",
          close: "font-medium text-black",
          body: "p-5 pt-0",
          content: "rounded-xl",
          header: "p-5",
        }}
        size={647}
        centered
        opened={isPwdModalOpen}
        onClose={() => {
          setIsPwdModalOpen(false);
          form.reset();
        }}
        title="Change Password"
      >
        <form onSubmit={form.onSubmit(handlePasswordChange)}>
          <div className="flex gap-5 max-sm:flex-wrap">
            <div className="w-full">
              <p className="text-black text-sm font-normal pb-4">
                Current Password
              </p>
              <PasswordInput
                rightSection=""
                key={form.key("currentPassword")}
                {...form.getInputProps("currentPassword")}
                classNames={{
                  input:
                    "bg-stone-100 w-full !border-gray-200 rounded-lg shadow text-zinc-500 text-sm",
                }}
                type="password"
              />
            </div>
            <div className="w-full">
              <p className="text-black text-sm font-normal pb-4">
                New Password
              </p>
              <PasswordInput
                rightSection=""
                key={form.key("newPassword")}
                {...form.getInputProps("newPassword")}
                classNames={{
                  input:
                    "bg-stone-100 w-full !border-gray-200 rounded-lg shadow text-zinc-500 text-sm",
                }}
                type="password"
              />
            </div>
          </div>
          <div className="w-full pt-5">
            <p className="text-black text-sm font-normal pb-4">
              Confirm Password
            </p>
            <PasswordInput
              rightSection=""
              key={form.key("confirmPassword")}
              {...form.getInputProps("confirmPassword")}
              classNames={{
                input:
                  "bg-stone-100 w-full !border-gray-200 rounded-lg shadow text-zinc-500 text-sm",
              }}
              type="password"
            />
          </div>
          <div className="pt-5 float-end pb-5 flex max-[360px]:flex-wrap-reverse gap-y-2 gap-x-3.5">
            <Button
              onClick={() => {
                setIsPwdModalOpen(false);
                form.reset();
              }}
              className="rounded-[10px] !bg-transparent max-[360px]:w-full h-[42px] px-[30px] py-[11px] !text-zinc-500 text-sm font-semibold !border-gray-200"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type="submit"
              className="rounded-[10px] !ml-0 !bg-blue-500 max-[360px]:w-full h-[42px] px-[30px] py-[11px] text-sm font-semibold"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PasswordChangeModal;
